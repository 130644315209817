import { Component, OnInit } from '@angular/core';
import { Params, Router } from '@angular/router';
import { of } from 'rxjs';
import { concatMap, first } from 'rxjs/operators';
import * as _ from 'lodash';

import { UserAccountService, PasswordResetTypes } from '../../services/identity-am/user.account';
import { IResult } from '../../services/utilities/rest.client';
import { RouteNames } from '../../modules/app.route.names';

enum Modes {
    DEFAULT,
    LINK_EXPIRED,
    PASSWORD_RESET,
    COMPLETE,
}

@Component({
    selector: 'app-password-reset',
    templateUrl: './password.reset.html',
    styleUrls: ['./password.reset.scss']
})
export class PasswordResetComponent implements OnInit {
    newPassword: string;
    newPasswordOk = false;
    confirmPassword: string;
    hasError = false;
    errors: string[];
    type: PasswordResetTypes;
    PasswordResetTypes = PasswordResetTypes;
    iamToken: string;
    mode: Modes = Modes.DEFAULT;
    Modes = Modes;
    userId: string;
    resetButtonText: string;
    resetIntroText: string;

    constructor(
        private userAccountSvc: UserAccountService,
        private router: Router,
    ) { }

    private parseQueryString = (): void => {
        const params: Params = this.router.routerState.snapshot.root.queryParams;

        if (!params.type) {
            throw new Error('Missing type');
        }
        this.type = params.type;

        if (!params.token) {
            throw new Error('Missing token');
        }
        // @ts-ignore
        this.iamToken = _.flow(
            _.partial(_.get, _, ['token']),
            // @ts-ignore
            _.partial(_.replace, _, /\s/g, '+'), // Certain mail clients are url encoding '+' as a space, breaking the token
        )(params);
    }

    private initText = (): void => {
        this.resetIntroText = 'Let’s set a new password for you!';
        this.resetButtonText = 'Update';

        if (this.type === PasswordResetTypes.CREATE) {
            this.resetButtonText = 'Create Account';
            this.resetIntroText = 'Let’s finishing setting up your account!';
        }
    }

    public ngOnInit(): void {
        this.parseQueryString();
        this.initText();
        this.userAccountSvc.getUserClaimsByIamToken(this.iamToken)
            .pipe(first())
            .subscribe((result: IResult) => {
                if (!result.success) {
                    this.mode = Modes.LINK_EXPIRED;
                    return;
                }

                this.mode = Modes.PASSWORD_RESET;
                this.userId = result.payload.UserId;
            });
    }

    public updatePassword = (): void => {
        this.hasError = false;
        this.errors = null;

        this.userAccountSvc.newPasswordOK(this.userId, this.newPassword, this.iamToken)
            .pipe(
                first(),
                concatMap((result: IResult) => {
                    if (!result.success) {
                        return of(result);
                    }

                    return this.userAccountSvc.changePassword(this.userId, this.newPassword, this.iamToken);
                }),
                concatMap((result: IResult) => {
                    if (!result.success) {
                        return of(result);
                    }

                    return this.userAccountSvc.authenticate(result.payload.UserName, this.newPassword);
                })
            )
            .subscribe((result: IResult) => {
                if (result.success) {
                    this.mode = Modes.COMPLETE;
                } else {
                    this.hasError = true;
                    if (result.error.status === 400) {
                        this.errors = _.get(result, ['error', 'response', 'error'], ['Unknown Error']);
                    }
                }
            });
    }

    public continueAction = (): void => {
        this.router.navigate([RouteNames.Home]);
    }
}
