import { Component, OnInit } from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';
import { concatMap, first } from 'rxjs/operators';

import { SessionVariablesUtility } from '../../services/utilities/session.variables';
import { UserAccount } from '../../models/user.account';
import { UserAccountService } from '../../services/identity-am/user.account';
import { Claim } from '../../models/user.account';
import { IResult } from '../../services/utilities/rest.client';

@Component({
    selector: 'user-component',
    templateUrl: './user.html',
    styleUrls: ['./user.scss'],
    animations: [
        trigger('inputHeightAnimate', [
            transition(':enter', [
                style({maxHeight: 0}),
                animate('400ms', style({maxHeight: '1000px'}))
            ]),
            transition(':leave', [
                style({maxHeight: '1000px'}),
                animate('400ms', style({maxHeight: 0}))
            ])
        ])
    ]
})

export class UserComponent implements OnInit {

    sessData: any = null;

    userCurrentPassword: string = '';
    userCurrentPasswordCorrect: boolean = false;
    userNewPasswordOne: string = '';
    userNewPasswordTwo: string = '';

    userUpdating: boolean = false;
    inChangeMode: boolean = false;
    inEditPasswordMode: boolean
    modalVisible: boolean = false;
    //
    // logged in portal user fields
    //
    portalUser: UserAccount = null;
    confirmPrtlPassword: string = null;
    prtlPasswordOK: boolean = true;
    prtlPasswordConfirmOK: boolean = true;
    prtlUserSvMessage: string;

    constructor(private sessSvc: SessionVariablesUtility,
                private userSvc: UserAccountService) {
        this.sessData = sessSvc.getLoggedInUser();

        userSvc.getUserById(sessSvc.getCurrentAuthInfo().Id)
            .subscribe(
                (usr: UserAccount) => {
                    this.portalUser = usr;
                    this.portalUser.Claims = this.portalUser.Claims.filter( (clm: Claim) => {
                        // ignore old claims from conversion irrelevant (RateTrack roles)
                        return clm.Value.toUpperCase().indexOf('UUG') < 0 && clm.Value.toUpperCase().indexOf('DEALER') !== 0;
                    });

                    this.prtlPasswordConfirmOK = this.prtlPasswordOK = true;
                    console.log(this.portalUser);
                }
            )
    }

    ngOnInit(): any {
    }

    toggleChangeMode(value) {
        this.inChangeMode = value;
        this.userCurrentPassword = '';
        this.userNewPasswordOne = '';
        this.userNewPasswordTwo = '';
    }

    //
    //#region Logged in user component methods
    //


    // make sure confirmation password matches
    checkPortalUserPasswordMatch() {
        this.prtlPasswordConfirmOK = this.portalUser.Password === this.confirmPrtlPassword;
    }

    public checkPasswordCorrect(): void {
        this.userSvc.authenticate(this.sessSvc.getLoggedInUser().UserName, this.userCurrentPassword)
            .subscribe((result: IResult) => {
                this.userCurrentPasswordCorrect = result.success;
            });
    }

    updatePortalUser() {
        this.userUpdating = true;
        if (this.userNewPasswordOne) {
            this.userSvc.changePassword(this.portalUser.Id, this.userNewPasswordOne)
                .pipe(
                    first(),
                    concatMap((result: IResult) => {
                        if (!result.success) {
                            throw result.error;
                        }

                        this.prtlUserSvMessage = 'Update successful.';

                        const user: UserAccount = result.payload;
                        user.FirstName = this.portalUser.FirstName;
                        user.LastName = this.portalUser.LastName;

                        return this.userSvc.saveUser(user, null, null);
                    })
                )
                .subscribe((user: UserAccount) => {
                    this.userUpdating = false;
                    this.portalUser = user;
                    this.prtlUserSvMessage = 'Update successful';
                    this.modalVisible = true;
                    this.toggleChangeMode(false);
                }, () => {
                    this.userUpdating = false;
                    this.prtlUserSvMessage = 'Error saving user';
                    this.modalVisible = true;
                });
        } else {
            this.userSvc.saveUser(this.portalUser, null, null)
                .subscribe((svUsr: UserAccount) => {
                    this.userUpdating = false;
                    this.portalUser = svUsr;
                    this.prtlUserSvMessage = 'Update successful';
                    this.modalVisible = true;
                    this.toggleChangeMode(false);
                }, () => {
                    this.userUpdating = false;
                    this.prtlUserSvMessage = 'Error saving user';
                    this.modalVisible = true;
                });
        }
    }

    //#endregion
    // This function binds to an eventEmitter to toggle our value from the modal component
    hideModal($event){
        this.modalVisible = $event;
    }

}
