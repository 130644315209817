import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'small-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

    @Input() modalVisible: boolean;
    @Input() modalMessage: String;
    @Output() toggleModalVisible: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit() {
    }

    toggle() {
        this.modalVisible = !this.modalVisible;
        this.toggleModalVisible.emit(this.modalVisible);
    }
}
