<div class="main-container">
    <div class="navbar-container overlay">
        <div class="navbar-inner-container">
            <a href="https://www.taillight.com/" target="_blank">
                <div class="logo"></div>
            </a>
        </div>
    </div>
    <div class="form-container">
        <div class="flex flex-column flex-align-center" style="max-width: 420px; flex: 1;">
            <div *ngIf="mode === Modes.LINK_EXPIRED" class="flex flex-column flex-align-center">
                <p style="width: 320px; margin-bottom: 24px; text-align: center; color: #fff;">The link has expired.</p>
                <a href="/login?password-reset=true&type={{type}}" class="p-75" style="text-decoration: none;">
                    <button class="btn btn-primary p-100 pointer">
                        {{type === PasswordResetTypes.RESET ? 'Send Reset' : 'Resend Create'}} Email
                    </button>
                </a>
            </div>

            <div *ngIf="mode === Modes.COMPLETE">
                <p style="margin-bottom: 24px; text-align: center; color: #fff; font-weight: bold; font-size: 24px;">
                    Thanks!
                </p>
                <p style="margin-bottom: 24px; text-align: center; color: #fff;">
                    Your account password has been updated.
                </p>
                <div class="p-100 flex flex-justify-center">
                    <button (click)="continueAction()" class="btn btn-primary p-75">
                        Continue
                    </button>
                </div>
            </div>

            <form
                *ngIf="mode == Modes.PASSWORD_RESET"
                class="flex flex-column flex-align-center container"
                (ngSubmit)="updatePassword()"
                #PasswordResetForm="ngForm"
            >
                <div>
                    <p class="text-large-x" style="margin-bottom: 24px; text-align: center; color: #fff;">
                        {{resetIntroText}}
                    </p>
                </div>

                <div style="width: 320px;">
                    <div class="margin-top ml2 input-label">Set Password</div>
                    <div>
                        <div class="row-horizontal relative">
                            <div class="p-100">
                                <input
                                    ngControl="newPassword"
                                    [(ngModel)]="newPassword"
                                    class="form-control"
                                    name="newPassword"
                                    id="newPassword"
                                    type="password"
                                    #cPassword="ngModel"
                                    required
                                    minlength="8"
                                    pattern="^(?=[\x21-\x7E]*[0-9])(?=[\x21-\x7E]*[A-Z])(?=[\x21-\x7E]*[a-z])(?=[\x21-\x7E]*[\x21-\x2F|\x3A-\x40|\x5B-\x60|\x7B-\x7E])[\x21-\x7E]{8,32}$"
                                >
                            </div>
                            <div *ngIf="cPassword.touched && cPassword.invalid">
                                <svg class="input-status-icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                    <g fill="none" fill-rule="evenodd">
                                        <circle cx="15" cy="15" r="15" fill="#CF0A2C" />
                                        <path fill="#FFF" fill-rule="nonzero" d="M14,8 L16,8 L16,18 L14,18 L14,8 Z M14,21 C14,22.3333333 16,22.3333333 16,21 C16,19.6666667 14,19.6666667 14,21 Z" />
                                    </g>
                                </svg>
                            </div>
                            <div *ngIf="cPassword.valid">
                                <svg class="input-status-icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                  <g fill="none" fill-rule="evenodd">
                                    <circle cx="15" cy="15" r="15" fill="#0EB30F" fill-rule="nonzero"/>
                                    <polyline stroke="#FFF" points="9 16.949 12.949 20 21 9"/>
                                  </g>
                                </svg>
                            </div>

                            <div
                                *ngIf="cPassword.invalid"
                                class="password-requirements list-container pl5 pt3"
                            >
                                <ul class="has-bullets pl2 pr3">
                                    <li class="font-regular pb2">Be a minimum of eight (8) characters in length.</li>
                                    <li class="font-regular pb2">Contain both upper and lowercase characters (e.g. A-Z, a-z)</li>
                                    <li class="font-regular pb2">Have at least one numerical character (e.g. 0-9)</li>
                                    <li class="font-regular pb2">Have at least one special character (e.g. !@#$?~-_)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="margin-top ml2 input-label">Confirm Password</div>
                    <div class="margin-bottom p-100">
                        <div class="row-horizontal relative">
                            <div class="p-100">
                                <input
                                    ngControl="confirmPassword"
                                    class="form-control"
                                    [(ngModel)]="confirmPassword"
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    type="password"
                                    #cPasswordConfirm="ngModel"
                                    required
                                />
                            </div>
                            <div *ngIf="cPasswordConfirm.touched && cPasswordConfirm.value !== cPassword.value">
                                <svg class="input-status-icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                    <g fill="none" fill-rule="evenodd">
                                        <circle cx="15" cy="15" r="15" fill="#CF0A2C" />
                                        <path fill="#FFF" fill-rule="nonzero" d="M14,8 L16,8 L16,18 L14,18 L14,8 Z M14,21 C14,22.3333333 16,22.3333333 16,21 C16,19.6666667 14,19.6666667 14,21 Z" />
                                    </g>
                                </svg>
                            </div>
                            <div *ngIf="cPassword.valid && cPasswordConfirm.value === cPassword.value">
                                <svg class="input-status-icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                  <g fill="none" fill-rule="evenodd">
                                    <circle cx="15" cy="15" r="15" fill="#0EB30F" fill-rule="nonzero"/>
                                    <polyline stroke="#FFF" points="9 16.949 12.949 20 21 9"/>
                                  </g>
                                </svg>
                            </div>
                            <div
                                *ngIf="cPassword.valid && cPasswordConfirm.touched && cPasswordConfirm.value !== cPassword.value"
                                class="password-requirements password-requirements-confirm list-container p3"
                            >
                                Passwords do not match!
                            </div>
                        </div>
                    </div>
                    <div class="p-100 pull-right login-button-group mt3">
                        <button
                            type="submit"
                            class="btn btn-primary p-100"
                            [disabled]="cPassword.invalid || cPasswordConfirm.value !== cPassword.value"
                        >
                            {{resetButtonText}}
                        </button>
                    </div>
                </div>

                <div *ngIf="hasError" class="provider-errors list-container py3 mt3" >
                    <div class="text-center" *ngIf="!errors">Error Updating Password</div>
                    <div class="pl5" *ngIf="errors">
                        <div class="mb3 text">It seems your account has additional<br />password requirements.</div>
                        <ul class="has-bullets pl2 pr3">
                            <li *ngFor="let error of errors" class="font-regular pb2">
                                {{error}}
                            </li>
                        </ul>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
