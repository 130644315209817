import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SafeResourceUrl, } from '@angular/platform-browser';
import { animate, style, transition, trigger } from '@angular/animations';
import { Observable, of } from 'rxjs';
import { reject, some } from 'lodash';

import { RouteNames } from '../../modules/app.route.names';
import { WebServiceError } from '../../models/web.service.error';
import { DealershipService } from '../../services/identity-am/dealerships';
import { DealershipModel } from '../../models/dealership';
import { TLApplication } from '../../models/tlapplication';
import { TLApplicationService } from '../../services/identity-am/tlapplication';
import { SessionVariablesUtility } from '../../services/utilities/session.variables';
import { WindowRef } from '../../modules/window.helper';
import { ProviderService } from '../../services/identity-am/providers';
import { ProviderModel } from '../../models/provider';
import { UserAccountService } from '../../services/identity-am/user.account';
import { AppSettingService } from '../../services/identity-am/app.setting';
import { Claim, UserAccount } from '../../models/user.account';
import { Config } from '../../services/config';
import { TLAPPLICATIONS } from '../../services/identity-am/tlapplication';

const MAX_RECENT_COUNT = 5;
const SEARCH_PLUS = new RegExp('\\+', 'g');

@Component({
    selector: 'app-portal-home',
    templateUrl: './portal.home.html',
    styleUrls: ['./portal.home.scss'],
    animations: [
        trigger('expandableHeightAnimate', [
            transition(':enter', [
                style({ maxHeight: 0 }),
                animate('1000ms', style({ maxHeight: '1000px' }))
            ]),
            transition(':leave', [
                style({ maxHeight: '1000px' }),
                animate('1000ms', style({ maxHeight: 0 }))
            ])
        ])
    ]
})
export class PortalHomeComponent implements OnInit {

    isPortalAdmin = false;

    dealerSearchName: string = null;
    userDealerships: DealershipModel[] = null;
    allUserDealerships: DealershipModel[] = null;
    recentDealerships: DealershipModel[] = null;
    selectedDealership = 'This is the default';
    selectedDealershipName: string;
    recentDealership: string;

    allDealerApplications: TLApplication[] = null;
    dealerApplications: TLApplication[] = [];
    selectedApplication: string = null;

    embedUrl: SafeResourceUrl = null;
    embedUrlS: string;
    hideApp: boolean;

    userProvider: ProviderModel = null;
    userProviderId: string = null;
    userFirstName = '';
    userLastName = '';
    userEmail = '';
    userClaims: Claim[];

    @ViewChild('dlrChoicesPnl', { static: true }) dlrChoicesPnl: any;
    @ViewChild('appChoicesPnl', { static: true }) appChoicesPnl: any;
    @ViewChild('userAccountPnl', { static: true }) userAccountPnl: any;

    // UI Vars
    dropdownAnimating = false;
    showUserComponent = false;
    showAccountManagement = false;
    showCoachTooltip = false;
    showCoachRipple = false;
    isSearching = false;
    isInitialSearch = false;
    isFirstTimeUser = false;
    searchFocused = false;
    currentProvider = '';
    showcaseAnnouncement = false;
    spanOnlyProvider = false;
    expDate: string;
    showCountDown = false;
    omitApps: Array<string> = [];

    constructor(
        private routerSvc: Router,
        private dealerSvc: DealershipService,
        private appSvc: TLApplicationService,
        private sessSvc: SessionVariablesUtility,
        private providerSvc: ProviderService,
        private userSvc: UserAccountService,
        private windowRef: WindowRef,
        private config: Config,
        private appSettingSvc: AppSettingService
    ) { }

    public ngOnInit(): void {
        if (this.sessSvc.getLoggedInUser() === null) {
            this.routerSvc.navigate([RouteNames.Login]);
        }

        this.clearSelected();

        this.getUserInfo().subscribe(() => {
            this.currentProvider = this.sessSvc.getProviderName().toUpperCase();

            this.appSettingSvc.getTLCommerceFeatureProviderSetting('OMIT-TLCOMMERCE-APP', 'false')
                .subscribe((omitCommerce: string) => {
                    if (omitCommerce === 'true') {
                        this.omitApps.push('TAILLIGHTCOMMERCE');
                    }

                    this.appSettingSvc.getTLCommerceFeatureProviderSetting('OMIT-MENU-APP', 'false')
                        .subscribe((omitMenu: string) => {
                            if (omitMenu === 'true') {
                                this.omitApps.push('MENUPRO');
                            }

                            this.appSvc.getTlSsoApplications()
                                .subscribe((apps: TLApplication[]) => {
                                    this.allDealerApplications = apps;
                                    this.getInitialDealerships();
                                });
                        });
                    });
        });

        this.appSettingSvc.getShowCaseFeatureProviderSetting('SHOW-ANNOUNCEMENT', 'false')
            .subscribe((showAnnouncement: string) => { this.showcaseAnnouncement = showAnnouncement.toLowerCase() === 'true'; });

        this.appSettingSvc.getSPANFeatureProviderSetting('span-only', 'false')
            .subscribe((spanOnly: string) => { this.spanOnlyProvider = spanOnly.toLowerCase() === 'true'; });

        this.isPortalAdmin = this.sessSvc.getUserIsPortalAdmin();
    }

    onDealerSearchChange(): void {
        this.searchDealers();
    }

    locateDealership(id: string): Observable<DealershipModel> {
        const foundDealership = (this.userDealerships || []).find(x => x.Id === id);
        return foundDealership
            ? of(foundDealership)
            : this.dealerSvc.getDealerById(id);
    }

    onDealerChange(): void {
        this.recentDealership = null;
        this.dealerSvc.addRecentDealership(this.selectedDealership, MAX_RECENT_COUNT);
        this.dealerSvc.setRecentDealership(this.selectedDealership);
        this.locateDealership(this.selectedDealership)
            .subscribe(
                (dlr) => {
                    this.collapseDealerChoices();
                    this.selectedDealershipName = dlr.LegalName;
                    this.verifyApps(dlr);
                }
            );
    }

    onRecentDealerChange(id: string): void {
        this.selectedDealership = null;
        this.recentDealership = id;
        this.dealerSvc.setRecentDealership(id);
        this.locateDealership(id)
            .subscribe(
                (dlr) => {
                    this.collapseDealerChoices();
                    this.selectedDealershipName = dlr.LegalName;
                    this.verifyApps(dlr);
                }
            );
    }

    private verifyApps = (dealer: DealershipModel): void => {
        this.selectedApplication = null;
        this.hideApp = true;

        const availApps = this.dealerSvc.availableApplications(
            dealer,
            this.allDealerApplications,
            this.omitApps,
            this.spanOnlyProvider ? 'span-only' : 'commerce-only'
        );

        this.dealerApplications = [];
        availApps.forEach((appName: string) => {
            this.dealerApplications.push(this.allDealerApplications.find((app: TLApplication) => {
                this.expDate = this.sessSvc.getExpirationDate() || '';
                return app.ApplicationName.toUpperCase() === appName;
            }));
        });

        const hasEnrollmentClaims = some(this.userClaims, (claim) => claim.Value === 'PORTAL ADMIN' || claim.Value === 'AGREEMENTS' || claim.Value === 'BILLING')

        if (!hasEnrollmentClaims) {
            this.dealerApplications = reject(this.dealerApplications, (dealerApplication) => dealerApplication.ApplicationName === 'ENROLLMENT');
        }

        this.expandAppChoices();
    }

    private verifyExpirationDateApps = (chosenDealer: string): void => {
        this.locateDealership(chosenDealer)
            .subscribe(
                (dlr) => {
                    this.selectedApplication = null;
                    this.hideApp = true;
                    const availApps =
                        this.dealerSvc.availableApplications(
                            dlr,
                            this.allDealerApplications,
                            this.omitApps,
                            this.spanOnlyProvider ? 'span-only' : 'commerce-only');
                            availApps.forEach((app: string) => {
                                this.allDealerApplications.find((x: TLApplication) => {
                                    this.expDate = this.sessSvc.getExpirationDate() || '';
                                    return x.ApplicationName.toUpperCase() === app;
                                });
                            });
                }
            );
    }

    onAppChange(_: any, app: string): void {
        this.jumpToSsoApplication(app);
    }

    formatJumpUrl = (url: string): string =>
        url
            .replace('{tok}', encodeURI(this.sessSvc.getCurrentUserToken()))
            .replace('{dlr}', this.recentDealership ? this.recentDealership : this.selectedDealership)
            .replace(SEARCH_PLUS, '%2b')

    getJumpUrlByAppName = (appName: string): string => {
        if (_.isEqual(TLAPPLICATIONS.MENUPRO, appName)) { return this.formatJumpUrl(this.config.get('menuProSsoUrl')); }
        if (_.isEqual(TLAPPLICATIONS.TRACKING, appName)) { return this.formatJumpUrl(this.config.get('reportingSsoUrl')); }
        if (_.isEqual(TLAPPLICATIONS.COMMERCE, appName)) { return this.formatJumpUrl(this.config.get('commerceSsoUrl')); }
        if (_.isEqual(TLAPPLICATIONS.SPAN, appName)) { return this.formatJumpUrl(this.config.get('spanSsoUrl')); }
        if (_.isEqual(TLAPPLICATIONS.TLSHOWCASE, appName) && _.isEqual(this.expDate, ' Subscription Required')) {
            return this.formatJumpUrl(this.config.get('showcaseUpgradeUrl'));
        }
        if (_.isEqual(TLAPPLICATIONS.TLSHOWCASE, appName)) { return this.formatJumpUrl(this.config.get('showcaseSsoUrl')); }
        if (_.isEqual(TLAPPLICATIONS.ENROLLMENT, appName)) {
            return this.formatJumpUrl(`${this.config.get('tlpEnrollmentDwaBaseUrl')}/login?auth={tok}`);
        }
    }

    jumpToSsoApplication(applicationName: string, target: string = '_blank'): void {
        this.hideApp = true;
        this.appSvc.setRecentApplication(applicationName);
        const jumpUrl = this.getJumpUrlByAppName(applicationName);
        this.selectedApplication = null;
        if (jumpUrl != null) {
            const nativeWin = this.windowRef.getNativeWindow();
            nativeWin.open(jumpUrl, target);
        }
    }

    searchDealers(): void {
        this.collapseAppChoices();
        const currProvider = this.sessSvc.getProviderName();
        this.showCountDown = false;
        this.clearSelected();
        this.performSearch((dealers: DealershipModel[]) => {
            this.userDealerships = dealers;
            if (dealers.length === 1) {
                this.selectedDealership = dealers[0].Id;
                this.dealerSvc.addRecentDealership(this.selectedDealership, MAX_RECENT_COUNT);
                this.dealerSvc.setRecentDealership(this.selectedDealership);
                this.selectedDealershipName = dealers[0].LegalName;
                this.collapseDealerChoices();
                this.verifyApps(dealers[0]);
                if (currProvider === 'ZURICH' && this.expDate !== 'ACTIVE SUBSCRIPTION') {
                    this.showCountDown = true;
                }
            } else {
                dealers.forEach(dlr => {
                    this.verifyExpirationDateApps(dlr.Id);
                    if (currProvider === 'ZURICH' && this.expDate !== 'ACTIVE SUBSCRIPTION') {
                        return this.showCountDown = true;
                    }
                });

                if (localStorage) {
                    if (!localStorage.isFirstTimeUser) {
                        this.showCoachRipple = true;
                        // show our coaching box for 5 seconds
                        setTimeout(() => {
                            this.showCoachRipple = false;
                        }, 5000);
                        localStorage.setItem('isFirstTimeUser', 'true');
                    }
                }
            }
            return;
        });
    }

    clearSelected(): void {
        this.hideApp = true;
        this.embedUrl = null;
        this.selectedDealership = null;
        this.selectedApplication = null;
        this.selectedDealershipName = null;
    }

    performSearch(callback: (x: DealershipModel[]) => any) {
        this.isSearching = this.isInitialSearch ? false : true;
        this.dealerSvc.searchDealers(this.dealerSearchName)
            .subscribe((dealers: DealershipModel[]) => {
                this.isSearching = false;
                this.isInitialSearch = false;
                if (callback) {
                    callback(dealers);
                }
            }, () => {
                this.isSearching = false;
                this.isInitialSearch = false;
            });
    }

    getInitialDealerships(): void {
        this.performSearch((dealers: DealershipModel[]) => {
            this.userDealerships = dealers;
            this.recentDealerships = [];
            const recentDealerIds = this.dealerSvc.getUserRecentDealerships(MAX_RECENT_COUNT);
            if (recentDealerIds && recentDealerIds.length > 0) {
                recentDealerIds.forEach((x: string) => {
                    this.locateDealership(x)
                        .subscribe(
                            (dlr) => {
                                this.recentDealerships.push(dlr);
                            }
                        );
                });
            }
            if (dealers.length === 1) {
                this.selectedDealership = dealers[0].Id;
                this.dealerSvc.addRecentDealership(this.selectedDealership, MAX_RECENT_COUNT);
                this.dealerSvc.setRecentDealership(this.selectedDealership);
                this.selectedDealershipName = dealers[0].LegalName;
                this.verifyApps(dealers[0]);
            } else {
                this.expandDealerChoices();
            }
        });
    }

    getUserInfo(): Observable<any> {
        return (new Observable<any>(observer => {
            const userId = this.sessSvc.getCurrentAuthInfo().Id;

            this.userSvc.getUserById(userId)
                .subscribe(
                    (usr: UserAccount) => {
                        this.userProviderId = usr.PrimaryProviderId;

                        this.providerSvc.getAllProviders()
                            .subscribe((providers: Array<ProviderModel>) => {
                                this.userProvider = providers.find(
                                    (val: ProviderModel) => {
                                        return val.Id === this.userProviderId;
                                    }
                                );
                                this.sessSvc.setProviderName(this.userProvider.ProviderName);
                                observer.next();
                                observer.complete();
                            }, (err: WebServiceError) => {
                                observer.error(err);
                            });
                    }
                );

            const userData = this.sessSvc.getLoggedInUser();

            this.userFirstName = userData.FirstName;
            this.userLastName = userData.LastName;
            this.userEmail = userData.Email;
            this.userClaims = userData.Claims;
        }));
    }

    clearLocalStorage(): void {
        localStorage.clear();
    }

    expandDealerChoices() {
        this.dlrChoicesPnl.nativeElement.classList.add('expanded');
    }

    collapseDealerChoices() {
        this.dlrChoicesPnl.nativeElement.classList.remove('expanded');
    }

    expandAppChoices() {
        this.appChoicesPnl.nativeElement.classList.add('expanded');
    }

    collapseAppChoices() {
        this.appChoicesPnl.nativeElement.classList.remove('expanded');
    }

    collapseAccountManagement() {
        this.userAccountPnl.nativeElement.classList.remove('expanded');
    }

    onExpandDropdown($event: any): void {
        // Get rid of the firstTimeUser Coach tooltip
        this.showCoachTooltip = false;
        this.dropdownAnimating = true;
        const dropDown = $event.target.parentElement.getElementsByClassName('dropdown-content')[0];

        dropDown.classList.toggle('expanded');
    }

    collapseAllPopDowns() {
        this.collapseAccountManagement();
        this.collapseAppChoices();
        this.collapseDealerChoices();
    }


    toggleShowAccountManagement() {
        this.collapseAccountManagement();
        this.showAccountManagement = !this.showAccountManagement;
    }

    // Handle when the user hits enter while the 'search' button is focused
    @HostListener('window:keyup', ['$event'])

    keyEvent(event: KeyboardEvent) {
        if (event.code && event.code === 'Enter') {
            if (this.searchFocused) {
                this.onDealerSearchChange();
            }
        }
    }

    handleSearchFocus() {
        this.searchFocused = true;
    }

    handleSearchBlur() {
        this.searchFocused = false;
    }
}
