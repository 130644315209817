/**
 */

export class AuthenticationModel {
    public TokenValue: string;
    public Id: string;
    public PasswordChangeNeeded: boolean;
    public ChangeReason: string;
    public PasswordChangePending: boolean;
    public ChangePendingMessage: string;

    constructor(authenticationData?: any) {
        this.TokenValue = authenticationData.TokenValue;
        this.Id = authenticationData.Id;
        this.PasswordChangeNeeded = authenticationData.PasswordChangeNeeded;
        this.ChangeReason = authenticationData.ChangeReason;
        this.PasswordChangePending = authenticationData.PasswordChangePending;
        this.ChangePendingMessage = authenticationData.ChangePendingMessage;
    }
}
