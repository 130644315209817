import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';

import { RouteNames } from '../../modules/app.route.names';
import { AuthenticationModel } from '../../models/user.auth';
import { SessionVariablesUtility } from '../../services/utilities/session.variables';
import { UserAccount, Claim } from '../../models/user.account';
import { UserAccountService } from '../../services/identity-am/user.account';
import { CoreUserTLIMConvertService } from '../../services/core-apps/core.app.convert';
import { TemporaryTokenService } from '../../services/utilities/temp.token';
import { DealershipService } from '../../services/identity-am/dealerships';
import { ProviderService } from '../../services/identity-am/providers';
import { concatMap, catchError } from 'rxjs/operators';
import { DealershipModel } from '../../models/dealership';
import { TLAPPLICATIONS } from '../../services/identity-am/tlapplication';
import { Config } from '../../services/config';
import { EmailService } from '../../services/identity-am/email';
import { thanksEmail } from '../../../assets/emails/thanksEmail';
import { TLApplicationService } from '../../services/identity-am/tlapplication';
import { TLApplication } from '../../models/tlapplication';
import { WindowRef } from '../../modules/window.helper';

import * as _ from 'lodash';

const SRCHPLUS = new RegExp('\\+', 'g');

@Component({
    selector: 'newaccount-creation',
    templateUrl: './newaccount.creation.html',
    styleUrls: ['./newaccount.creation.scss'],
    animations: [
        trigger('showModal', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('250ms', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('250ms', style({ opacity: 0 }))
            ]),
        ])
    ]
})
export class NewAccountCreationComponent implements OnInit {
    @ViewChild('cPassword', { static: false }) cPassword;

    constructor(
        private routerSvc: Router,
        private sessionSvc: SessionVariablesUtility,
        private tlConvertService: CoreUserTLIMConvertService,
        private tempTokenSvc: TemporaryTokenService,
        private userAccountSvc: UserAccountService,
        private dealerSvc: DealershipService,
        private providerSvc: ProviderService,
        private config: Config,
        private emailSvc: EmailService,
        private appSvc: TLApplicationService,
        private windowRef: WindowRef
    ) {
    }

    AccountCreateModes = {
        CheckConverted: 'CHECK-CONVERTED',
        CreateAccount: 'CREATE-ACCOUNT',
        PreviousFound: 'PREVIOUS-FOUND',
        SkipForNow: 'NOT_IMPLEMENTED',
        Thanks: 'THANKS-DONE'
    };

    currentMode: string = this.AccountCreateModes.CreateAccount;
    okToSSOToCoreApp = false;
    convertedAccounts = [];
    convertedAccountDisplayGroups = [];

    createAccount = {
        emailAddress: '',
        passWord: '',
        confirmPassWord: '',
        emailError: '',
        confirmPassWordError: '',
        accountCreationError: '',
        firstName: '',
        firstNameError: '',
        lastName: '',
        lastNameError: ''
    };

    skipCreate = {
        emailAddress: '',
        emailError: '',
        firstName: '',
        firstNameError: '',
        lastName: '',
        lastNameError: '',
        accountCreationError: '',
    };

    previous = {
        error: '',
    };

    tlApplications: TLApplication[] = [];
    destinationDealership: DealershipModel = null;
    availableApplications: TLApplication[] = [];
    baseUrl: string;
    fordProtectLoginHelp: string;
    taillightBaseUrl: string;
    showAvoidScreenModal = false;

    ngOnInit(): any {
        const oldLoginInfo = this.sessionSvc.OldAccountInfo;
        this.currentMode = this.sessionSvc.CurrentConvertUserMode ||
            (oldLoginInfo && oldLoginInfo.menuProAuthSuccess) ?
                this.AccountCreateModes.CheckConverted :
                this.AccountCreateModes.CreateAccount;
        this.okToSSOToCoreApp = (this.sessionSvc.CoreAppFrom || '').length > 0;

        this.tempTokenSvc.getTemporaryToken()
            .subscribe((token: string) => {
                this.sessionSvc.setCurrentUserToken(token);
                this.getTargetDealership();

                if (oldLoginInfo && oldLoginInfo.menuProAuthSuccess && this.currentMode === this.AccountCreateModes.CheckConverted) {
                    this.userAccountSvc.getConvertedUsersByLegacyId('Login.MenuPro', oldLoginInfo.username)
                        .subscribe( (users = []) => {
                            this.convertedAccounts = users;
                            const accountsPerColumn = users.length <= 10 ? 5 : Math.ceil(users.length / 2);
                            this.convertedAccountDisplayGroups = _.chunk(users, accountsPerColumn);
                            if (!users.length) {
                                this.currentMode = this.AccountCreateModes.CreateAccount;
                                this.initCreationInfo();
                            }
                        });
                } else if (this.currentMode === this.AccountCreateModes.CreateAccount) {
                    this.initCreationInfo();
                }

                this.appSvc.getTlSsoApplications()
                    .subscribe((apps: TLApplication[]) => {
                        this.tlApplications = apps;
                    });
            });
        this.baseUrl = this.config.get('baseUrl');
        this.fordProtectLoginHelp = this.config.get('fordProtectLoginHelp');
        this.taillightBaseUrl = this.config.get('taillightBaseUrl');
    }

    initCreationInfo = () => {
        this.createAccount = {
            emailAddress: '',
            passWord: '',
            confirmPassWord: '',
            emailError: '',
            confirmPassWordError: '',
            accountCreationError: '',
            firstName: '',
            firstNameError: '',
            lastName: '',
            lastNameError: ''
        };
        const acctInfo = this.sessionSvc.OldAccountInfo;
        if (acctInfo && acctInfo.ristkenCoreAuthSuccess && !acctInfo.menuProAuthSuccess) {
            this.createAccount.firstName = acctInfo.firstName;
            this.createAccount.lastName = acctInfo.lastName;
            this.skipCreate.firstName = acctInfo.firstName;
            this.skipCreate.lastName = acctInfo.lastName;
        }
    }

    getTargetDealership = () => {
        const oldLoginInfo = this.sessionSvc.OldAccountInfo;
        if (oldLoginInfo && oldLoginInfo.ristkenCoreOrgId > 0) {
            this.dealerSvc.getRistkenCoreConvertedDealer(oldLoginInfo.ristkenCoreOrgId)
                .subscribe(
                    (dlr) => { this.destinationDealership = dlr; },
                    (err) => { console.log(err); }
                );
        } else if (oldLoginInfo) {
            this.dealerSvc.getMenuProConvertedDealer(oldLoginInfo.menuProDealerId)
                .subscribe(
                    (dlr) => { this.destinationDealership = dlr; },
                    (err) => { console.log(err); }
                );
        }
    }

    clearTempToken = () => { this.sessionSvc.setCurrentUserToken(null); };
    //
    modeSetVals = (val) => {
        this.currentMode = val;
        this.sessionSvc.CurrentConvertUserMode = val;
    }
    // show thanks screen
    showThanks = () => { this.modeSetVals(this.AccountCreateModes.Thanks); };
    // create new account
    showCreateNew = () => {
        this.initCreationInfo();
        this.modeSetVals(this.AccountCreateModes.CreateAccount);
    }
    // navigate to login page
    navigateLogin = () => {
        this.clearTempToken();
        this.routerSvc.navigate([RouteNames.Login]);
    }
    // navigate to portal home
    navigateHome = () => {
        this.routerSvc.navigate([RouteNames.Home]);
    }

    setupEssentialVariables = (usr): Observable<any> => {
        const oldLoginInfo = this.sessionSvc.OldAccountInfo;
        return this.userAccountSvc.getLoginToken(usr.Id)
            .pipe(
                concatMap(
                    (token) => {
                        const tok = token.TokenValue;
                        this.setupHomeSessionVars(usr, tok, oldLoginInfo.provider);
                        return of(token);
                    }
                )
            );
    }

    // setup portal variables
    setupHomeSessionVars = (usr, tok, provider) => {
        this.sessionSvc.setCurrentAuthInfo(this.buildAuthInfo(usr, tok));
        this.sessionSvc.setCurrentUserToken(tok);
        this.sessionSvc.setLoggedInUser(usr);
        this.sessionSvc.setProviderName(provider);
        this.sessionSvc.setUserIsPortalAdmin(
            (usr.Claims || []).find((clm: Claim) => clm.Value.toUpperCase() === 'PORTAL ADMIN') != null
        );
    }
    // authentication info
    buildAuthInfo = (usr, tok): AuthenticationModel => {
        const authInfo = new AuthenticationModel({
            Id: usr.Id,
            TokenValue: tok,
            PasswordChangeNeeded: false,
            PasswordChangePending: false,
            ChangePendingMessage: null,
            ChangeReason: null
        }
        );
        return authInfo;
    }

    createUserAccount = () => {
        this.createUserWithCompletion(
            () => this.createAccount,
            (val) => this.createAccount.accountCreationError = val,
            () => this.createAccount.passWord,
            () => this.sendThanksAndContinue()
        );
    }
    // create the user (either conversion of mpe user, or new user account)
    createUserWithCompletion = (accountInfoFunc, errorInfoFunc, passwordInfoFunc, completionFunc) => {
        const oldLoginInfo = this.sessionSvc.OldAccountInfo;

        this.providerSvc.getProviderByCode(oldLoginInfo.provider)
            .subscribe(
                (provider) => {
                    if (!provider) { throw new Error('Could not find provider: ' + oldLoginInfo.provider); }
                    if (!oldLoginInfo.menuProAuthSuccess && oldLoginInfo.ristkenCoreUserId && parseFloat(oldLoginInfo.ristkenCoreUserId) > 0) {
                        // easiest to do :: convert the user account with a new email/pwd
                        this.convertRistkenCoreUserNewAccount(oldLoginInfo.ristkenCoreUserId, accountInfoFunc, passwordInfoFunc)
                            .subscribe(
                                (val) => {
                                    if (val.message.toLowerCase() === 'success') {
                                        completionFunc();
                                    } else {
                                        errorInfoFunc('We are sorry. We could not create your account.');
                                    }
                                },
                                () => { errorInfoFunc('Account could not be created.'); }
                            );
                    } else {
                        // this is a 'shared' login we are converting (menupro only)
                        const userAcc = this.userAccountSvc.buildNewUser(
                            accountInfoFunc().firstName,
                            accountInfoFunc().lastName,
                            accountInfoFunc().emailAddress,
                            ['SPAN F and I Manager'], // menu really doesn't have a role
                            passwordInfoFunc(),
                            oldLoginInfo.provider
                        );
                        userAcc.PrimaryProviderId = provider.Id;
                        userAcc.ForcePasswordChange = false;
                        userAcc.LegacyLinks.push({
                            LegacyApplication: 'Login.MenuPro',
                            LegacyTableId: oldLoginInfo.username.toString()
                        });
                        if (oldLoginInfo && oldLoginInfo.ristkenCoreUserId) {
                            userAcc.LegacyLinks.push({
                                LegacyApplication: 'Ristken.Core',
                                LegacyTableId: oldLoginInfo.ristkenCoreUserId.toString()
                            }
                            );
                        }
                        this.dealerSvc.getMenuProConvertedDealer(oldLoginInfo.menuProDealerId)
                            .subscribe(
                                (dlr) => {
                                    this.saveNewMenuUser(userAcc, dlr.Id)
                                        .subscribe(
                                            (updatedDlr) => {
                                                if (!updatedDlr) {
                                                    errorInfoFunc('Could not set up user with appropriate dealership(s).');
                                                } else {
                                                    completionFunc();
                                                }
                                            },
                                            (err) => {
                                                errorInfoFunc('Acount could not be created.');
                                            }
                                        );
                                },
                                () => {
                                    errorInfoFunc('Dealership could not be found.');
                                }
                            );
                    }
                }
            );
    }
    // save a new user account
    saveNewMenuUser = (usr: UserAccount, convertedImDealerId: string): Observable<DealershipModel> => {
        return this.userAccountSvc.saveUser(usr, null, null)
            .pipe(
                concatMap(
                    (svdUser) => {
                        return this.dealerSvc.addUserAccount(convertedImDealerId, svdUser.Id);
                    }
                ),
                catchError((err) => of(err))// BadRequest not ok
            );
    }
    // convert the old account
    convertRistkenCoreUserNewAccount = (oldRCId: number, accountInfoFunc, passwordFunc): Observable<any> => {
        accountInfoFunc().accountCreationError = null;
        return this.tlConvertService.coreUserConvertToTLIM(
            accountInfoFunc().emailAddress,
            passwordFunc(),
            oldRCId,
            this.sessionSvc.getCurrentUserToken());
    }

    checkEmailOk = (): void => {
        this.createAccount.accountCreationError = null;
        this.userAccountSvc.userNameAvailable(this.createAccount.emailAddress)
            .subscribe((ok: boolean) => {
                this.createAccount.emailError = ok ? null : 'Email already in use. Your account may already be set up.';
            });
    }

    checkPasswordMatch = () => {
        this.createAccount.accountCreationError = null;
        this.createAccount.confirmPassWordError =
            this.createAccount.passWord !== this.createAccount.confirmPassWord ? 'The passwords do not match.' : null;
    }

    sendThanksAndContinue = () => {
        this.userAccountSvc.getByUserName(this.createAccount.emailAddress)
            .subscribe(
                (usrs) => {
                    const user = usrs[0];
                    this.sendThanksEmail(user)
                        .subscribe(
                            () => {
                                this.getAvailableApplications(user);
                                this.maybeOverloadExploreButtonAction(user);
                                this.showThanks();
                            }
                        );
                }
            );
    }

    exploreButtonAction = () => {
        this.routerSvc.navigate([RouteNames.Home])
    };

    maybeOverloadExploreButtonAction = (user) => this.dealerSvc.getUserDealerships(user.PrimaryProviderId, user.Id)
        .subscribe((dealerships: DealershipModel[] = []) => {
            if (dealerships.length === 1) {
                this.exploreButtonAction = () => {
                    const jumpUrl = this.formatJumpUrl(this.config.get('showcaseSsoUrl'), dealerships[0].Id);
                    const nativeWin = this.windowRef.getNativeWindow();
                    nativeWin.open(jumpUrl, '_blank');
                }
            }
        })

    formatJumpUrl = (url: string, dealerId: string): string => {
        return url
            .replace('{tok}', encodeURI(this.sessionSvc.getCurrentUserToken()))
            .replace('{dlr}', dealerId)
            .replace(SRCHPLUS, '%2b');
    }

    // forward to SSO core app
    proceedToCoreAppSSo = () => {
        const oldLoginInfo = this.sessionSvc.OldAccountInfo;
        const app = this.sessionSvc.CoreAppFrom;
        if (oldLoginInfo.menuToken && app === TLAPPLICATIONS.MENUPRO) {
            this.skipToMenuPro(oldLoginInfo.menuToken);
        } else if (oldLoginInfo.ristkenCoreUserEnc && app === TLAPPLICATIONS.TRACKING) {
            this.skipToReporting(oldLoginInfo.ristkenCoreUserEnc);
        } else if (oldLoginInfo.ristkenCoreUserEnc && app === TLAPPLICATIONS.SPAN) {
            this.skipToSPAN(oldLoginInfo.ristkenCoreUserEnc, oldLoginInfo.provider);
        } else {
            this.previous.error = 'Could not determine application to transfer to.';
        }
    }
    // SSO to menupro
    skipToMenuPro = (tok: string) => {
        const url = `${this.config.get('menuProSsoUrl').split('?')[0]}?LoginToken=${tok}`;
        console.log(url);
        window.location.href = url;
    }
    // SSO to reporting
    skipToReporting = (encryptedUName: string) => {
        const form = window.document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', `${this.config.get('reportingSsoUrl').split('?')[0]}`);
        form.setAttribute('target', '_self');
        // Add all the data to be posted as Hidden elements
        form.appendChild(this.createHiddenElement('uname', encryptedUName));
        window.document.body.appendChild(form);
        form.submit();
    }
    // erate.taillight.com SSO
    skipToSPAN = (encryptedUname: string, provider: string) => {
        const url = `${this.config.get('spanSsoUrl').split('?')[0]}?rctk=${encryptedUname}&rcprv=${provider}`;
        console.log(url);
        window.location.href = url;
    }
    // prep report SSO vals
    createHiddenElement = (name: string, value: string): HTMLInputElement => {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('name', name);
        hiddenField.setAttribute('value', value);
        hiddenField.setAttribute('type', 'hidden');
        return hiddenField;
    }
    // thanks email (completed everything)
    sendThanksEmail = (user: UserAccount): Observable<any> => {
        const urlToEmail = this.config.get('baseUrl') + '/' + RouteNames.Login;

        return this.emailSvc.sendEmail(
            user.Email,
            'support@TailLight.com',
            'Tail Light Account Created',
            thanksEmail(urlToEmail)
        );
    }
    //
    getAvailableApplications(user: UserAccount): void {
        const coreApp = this.sessionSvc.CoreAppFrom;
        const oldLoginInfo = this.sessionSvc.OldAccountInfo;
        this.sessionSvc.setProviderName(oldLoginInfo.provider);
        this.setupEssentialVariables(user)
            .subscribe(
                () => {
                    const apps =
                        this.dealerSvc.availableApplications(
                            this.destinationDealership,
                            this.tlApplications,
                            [],
                            (coreApp || '') === TLAPPLICATIONS.SPAN ? 'span-only' : 'commerce-only');
                    this.availableApplications = [];
                    apps.forEach((ap: string) => {
                        this.availableApplications.push(this.tlApplications.find((x: TLApplication) => {
                            return x.ApplicationName.toUpperCase() === ap;
                        }));
                    });
                }
            );
    }

    obscureLoginName(login: string): string {
        let obscured = login;
        const loginParts = login.split('@');
        if (loginParts.length > 1) {
            const domainParts = loginParts[1].split('.');
            const obscuredComp = domainParts[0].substr(0, 1) + ''.padEnd(domainParts[0].length - 1, '*');
            obscured = `${loginParts[0]}@${obscuredComp}.${domainParts[1]}`;
        }
        return obscured;
    }
    redirectToStart(login: string): void {
        console.log(`Redirecting to ${login}`);
        this.routerSvc.navigateByUrl(`/${RouteNames.Login}?uname=${login}`);
    }
    switchView(mode: string): void {
        this.currentMode = mode;
    }

    public get formValid(): boolean {
        const {
            emailAddress,
            firstName,
            lastName,
            confirmPassWord,
            confirmPassWordError
        } = this.createAccount;

        return emailAddress &&
            firstName &&
            lastName &&
            this.cPassword.valid &&
            confirmPassWord &&
            !confirmPassWordError;
    }
}
