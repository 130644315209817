<div class="countdown flex flex-wrap flex-space-between p3 pb12">
    <div class="flex-3 flex left-column flex-column flex-align-center flex-justify-center px6 pt9">
        <div class="flex-1 flex flex-justify-center flex-align-center pb10">
            <img class="calendar-image pr3" src="../../../assets/images/countdown/calendar.svg" />
            <img class="action-image" src="../../../assets/images/countdown/action-required.svg"  />
        </div>
        <div class="pb3 text-center">
            <img class="action-image" src="../../../assets/images/countdown/text-sub-req.svg"  />
        </div>
        <p class="pb1 center-text text-large pb6">With your subscription, there will be no DMS Integration fees.</p>
        <a href="https://taillight.nomoremenu.com/upgrade" target="_blank"><button class="px7 py2">SET UP SUBSCRIPTION</button></a>
        <p class="pt6 pb2 text-large">Zurich recognizes some dealerships want the freedom to make independent decisions regarding the technology needs for their store. Accordingly, Zurich no longer pays the licensing costs for Tail Light.</p>
    </div>
    <div class="flex-2 right-column flex flex-column flex-align-center flex-space-between px6 pt9">
        <div class="sub-box px9 py6 mb6 full-width">
            <h2 class="pb4">SUBSCRIPTION INCLUDES</h2>
            <ul><li>PowerMenu</li>
                <li>Commerce</li>
                <li>PowerTracking <span> (Zurich Provided)</span></li>
                <li>DMS Integration</li>
            </ul>
        </div>
        <div class="sub-box-two px9 py6 full-width">
            <p>The new Showcase platform is also included with your subscription. <a href="https://taillight.nomoremenu.com/learn" target="_blank">Learn More ></a></p>
        </div>
    </div>
</div>
