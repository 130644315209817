/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main.app.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./main.app.component";
var styles_MainAppComponent = [i0.styles];
var RenderType_MainAppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MainAppComponent, data: {} });
export { RenderType_MainAppComponent as RenderType_MainAppComponent };
export function View_MainAppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_MainAppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "main-app", [], null, null, null, View_MainAppComponent_0, RenderType_MainAppComponent)), i1.ɵdid(1, 49152, null, 0, i3.MainAppComponent, [], null, null)], null, null); }
var MainAppComponentNgFactory = i1.ɵccf("main-app", i3.MainAppComponent, View_MainAppComponent_Host_0, {}, {}, []);
export { MainAppComponentNgFactory as MainAppComponentNgFactory };
