import {Component} from '@angular/core';

@Component({
    selector: 'main-app',
    templateUrl: './main.app.html',
    styleUrls: ['./main.app.scss']
})
export class MainAppComponent {
    title = 'TailLight Core Portal';
}
