import {Component, OnInit} from '@angular/core';
import {trigger, style, transition, animate} from '@angular/animations';

@Component({
    selector: 'countdown',
    templateUrl: 'countdown.html',
    styleUrls: ['countdown.scss']
})

export class CountdownComponent implements OnInit {

    constructor(
    ){}

    ngOnInit(){
    }
}

