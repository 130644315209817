/*
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/modules/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
*/

// The browser platform with a compiler
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';



import * as $ from 'jquery';

// The app module
import { AppModule } from './app/modules/app.module';
import { Config } from './app/services/config';

import * as _lodash from 'lodash';
declare global {
    const _: typeof _lodash;
}

// Compile and launch the module
function startApplication(config) {
    // enableProdMode();
    let extraProviders = [
        Config.forRoot(config)
    ];
    platformBrowserDynamic(extraProviders).bootstrapModule(AppModule);
}

function getConfig() {
    return new Promise((resolve, reject) => {
        $.get('config/default.json').always(function (data, type) {
            let defaultsJSON = type === 'success' ? data : {};
            $.get('config/consul.json').always(function (data, type) {
                let consulJSON = type === 'success' ? data : {};
                resolve(_.merge(defaultsJSON, consulJSON));
            });
        });
    });
}

// bootstrap when document is ready
document.addEventListener('DOMContentLoaded', () => {
    getConfig().then(config => startApplication(config));
    //    startApplication({});
});
