import { Observable, of } from 'rxjs';
import { map, concatMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Config } from '../config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import * as parser from 'xml2js';
import * as pathParser from 'xml2js-xpath';

const soapAuthRequest = (email: string, pwd: string, id: number, tok: string) => {
    return `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"
        xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
      <soap:Header>
        <ER_SoapAuthHeader xmlns="http://services.ristken.com">
          <AuthenticationUserName></AuthenticationUserName>
          <AuthenticationPassword>${tok}</AuthenticationPassword>
        </ER_SoapAuthHeader>
      </soap:Header>
      <soap:Body>
        <ConvertTLUserForIM xmlns="http://services.ristken.com">
          <userEmail>${email}</userEmail>
          <passWord>${pwd}</passWord>
          <ristkenCoreId>${id}</ristkenCoreId>
        </ConvertTLUserForIM>
      </soap:Body>
    </soap:Envelope>`;
};

const buildSoapRequestHeaders = (soapAction: string): HttpHeaders => {
    const reqOptions = new HttpHeaders({
        'Content-Type': 'text/xml',
        'SOAPAction': soapAction
    });
    return reqOptions;
};

const soapCoreAppAuthRequest = (userEmail: string, userPassword: string, oldId: number,
    tok: string, http: HttpClient, config: Config): Observable<any> => {
    return http.post(
        config.get('erateSignupUrl'),
        soapAuthRequest(userEmail, userPassword, oldId, tok),
        { headers: buildSoapRequestHeaders('http://services.ristken.com/ConvertTLUserForIM'), responseType: 'text' }
    );
};

const parseXmlToJson = (xml: string): Observable<any> => {
    return (new Observable<string>(observer => {
        parser.parseString(xml,
            (err: any, res: any) => {
                !!err ? observer.error(err) : observer.next(res);
                observer.complete();
            }
        );
    }));
};

const callCoreAppConvertToJson = (userEmail: string, userPassword: string, oldId: number,
    tok: string, http: HttpClient, config: Config): Observable<any> => {
    return soapCoreAppAuthRequest(
        userEmail, userPassword, oldId,
        tok, http, config
    )
        .pipe(
            concatMap((text) => {
                return parseXmlToJson(text);
            }),
            catchError((err) => of(err))// BadRequest not ok

        );
};

const parseJsonWithXPath = (jsonObj: any, xpath: string): any => {
    return pathParser.find(jsonObj, xpath) ? pathParser.find(jsonObj, xpath)[0] : null;
};

@Injectable()
export class CoreUserTLIMConvertService {
    constructor(
        private config: Config,
        private http: HttpClient) {
    }

    public coreUserConvertToTLIM = (userEmail: string, userPassword: string, oldId: number, token: string): Observable<any> => {
        return callCoreAppConvertToJson(userEmail, userPassword, oldId, token, this.http, this.config)
            .pipe(
                map(
                    (ret) => {
                        return {
                            message: parseJsonWithXPath(ret, '//Message'),
                        };
                    }
                )
            );
    }
}
