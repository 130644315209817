import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';
//components
import { MainAppComponent } from '../components/main.app/main.app.component';
import { NewAccountCreationComponent } from '../components/newaccount.creation/newaccount.creation.component';
import { routing, appRoutingProviders } from './app.routing';
import { PortalHomeComponent } from '../components/portal.home/portal.home.component';
import { AccountManagementComponent } from '../components/account.management/account.management.component';
import { UserComponent } from '../components/user/user.component';
import { CoachmarksComponent } from '../components/coachmarks/coachmarks.component';
import { CountdownComponent } from '../components/countdown/countdown.component';
import { PasswordResetComponent } from '../components/password.reset/password.reset.component';
import { ForgotPasswordComponent } from '../components/forgot.password/forgot.password.component';
import { ModalComponent } from '../components/modal/modal.component';
import { LoginComponent } from '../components/login/login.component';

import { SafePipe } from '../modules/safe.pipe';
import { WindowRef } from '../modules/window.helper';
//services
import { RESTServiceClient } from '../services/utilities/rest.client';
import { SessionVariablesUtility } from '../services/utilities/session.variables';
import { DealershipService } from '../services/identity-am/dealerships';
import { TLApplicationService } from '../services/identity-am/tlapplication';
import { ProviderService } from '../services/identity-am/providers';
import { UserAccountService } from '../services/identity-am/user.account';
import { EmailService } from '../services/identity-am/email';
import { TemporaryTokenService } from '../services/utilities/temp.token';
import { Config } from '../services/config';
import { AppSettingService } from '../services/identity-am/app.setting';
import { CoreAppAuthenticationService } from '../services/core-apps/core.app.auth';
import { CoreAppSignupService } from '../services/core-apps/core.app.signup';
import { CoreUserTLIMConvertService } from '../services/core-apps/core.app.convert';

@NgModule({
  declarations: [
    MainAppComponent,
    LoginComponent,
    PortalHomeComponent,
    SafePipe,
    AccountManagementComponent,
    CoachmarksComponent,
    CountdownComponent,
    PasswordResetComponent,
    ForgotPasswordComponent,
    UserComponent,
    ModalComponent,
    NewAccountCreationComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    routing,
    HttpClientModule,
    BrowserAnimationsModule
  ],
  providers: [
    appRoutingProviders,
    RESTServiceClient,
    SessionVariablesUtility,
    DealershipService,
    TLApplicationService,
    WindowRef,
    ProviderService,
    UserAccountService,
    EmailService,
    TemporaryTokenService,
    Config,
    AppSettingService,
    CoreAppAuthenticationService,
    CoreAppSignupService,
    CoreUserTLIMConvertService
  ],
  bootstrap: [MainAppComponent]
})
export class AppModule { }
