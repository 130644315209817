<div class="main-container flex flex-column flex-align-center flex-justify-start full-width full-height">
    <div class="avoid-screen-overlay flex flex-justify-center flex-align-center p6" *ngIf="showAvoidScreenModal" [@showModal]>
        <div class="modal px6 py9 text-center">
            <p class="text-large text-left">To avoid this screen in the future, please use the <span class="">new account credentials</span>  you recently created when signing in at
                <a href="baseUrl" target="_blank">{{baseUrl}}</a>
            </p>
            <button class="text-small mt9 mb6 py2 px18" (click)="proceedToCoreAppSSo()">OK, GOT IT</button>
        </div>
    </div>
    <main class="flex-1">
        <div class="signup-container">
            <div *ngIf="currentMode === AccountCreateModes.CheckConverted && convertedAccounts.length > 0" class="view-2 flex flex-column flex-align-center pt10" [@showModal]>
                <div class="pb15">
                    <div class="flex flex-justify-center pb2">
                        <img src="/assets/images/showcase-logo-red-bold.svg"/>
                    </div>
                    <p class="text-center">Modern features to simplify your workflow</p>
                </div>
                <h1 class="text-center text-large-xx pb4">Your account is active and your settings have been copied over!</h1>
                <h3 class="pb2 text-large">
                    There {{convertedAccounts.length === 1 ? 'is' : 'are'}} {{convertedAccounts.length}} updated {{convertedAccounts.length === 1 ? 'login' : 'logins'}} for this account.</h3>
                <br/>
                <div class="flex flex-1">
                    <div class="pl3 pr3" *ngFor="let displayGroup of convertedAccountDisplayGroups">
                        <div class="flex full-width pb2" *ngFor="let ca of displayGroup">
                            <div class="converted-account-email flex-1 flex flex-align-center pl3 pr8">{{ca.UserName}}</div>
                            <button class="converted-account-login-button py3 px5" (click)="redirectToStart(ca.UserName)">LOGIN</button>
                        </div>
                    </div>
                </div>
                <br/>
                <div class=" text-large text-center pb3 pt5">I need to create a new login</div>
                <button class="text-small-x full-width font-bold py3 bg-color-blue cursor-pointer" style="max-width: 250px;" (click)="switchView(AccountCreateModes.CreateAccount)">CREATE LOGIN</button>
                <div class="pt15">
                    <h2 class="text-center pb2">Training Resources</h2>
                    <div class="flex flex-justify-center flex-align-center pb1">
                        <img src="/assets/images/webcast-overview-icon.png" class="pr3 icon">
                        <p class="flex-1 font-bold">Webcast Overview</p>
                        <div class="flex">
                            <a class="pr1 resource-link" [href]="taillightBaseUrl+'/learning-center?training=true'" target="_blank">
                                Register Now
                            </a>
                            <img src="/assets/images/chevron-blue.svg">
                        </div>
                    </div>
                    <div class="flex flex-justify-center flex-align-center">
                        <img src="/assets/images/video-icon.png" class="pr3 icon">
                        <p class="flex-1 font-bold">Online Resources</p>
                        <div class="flex">
                            <a class="pr1 resource-link" [href]="taillightBaseUrl+'/learning-center'" target="_blank">
                               View Videos
                            </a>
                            <img src="/assets/images/chevron-blue.svg">
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="currentMode === AccountCreateModes.CreateAccount" class="view-2" [@showModal]>
                <div class="view-2 pb5 pt20" [@showModal]>
                    <h2 class="flex justify-content-center text-center">Activate Account</h2>
                    <br/>
                    <p class="pt1 text-center">Showcase uses a new login platform.<br />Let's get started!</p>
                </div>
                <div class="flex justify-content-center">
                    <div class="full-width flex flex-column flex-align-center">
                        <div class="text-input-container pb1">
                            <label class="text-small-xx ml2" for="emailaddr_create">Email Address (This will be your new username)</label>
                            <input
                                type="email"
                                name="emailaddr_create"
                                ngControl="emailaddr_create"
                                [(ngModel)]="createAccount.emailAddress"
                                id="emailaddr_create"
                                class="p2 mt1"
                                required
                                [ngModelOptions]="{updateOn: 'blur'}"
                                (ngModelChange)="checkEmailOk()"
                                [ngClass]="{ error: createAccount.emailError }"
                            />
                            <div class="text-small-x pt2">{{ createAccount.emailError }}</div>
                        </div>
                        <div class="text-input-container pb1">
                            <label class="text-small-xx ml2" for="firstname_create">First Name</label>
                            <input
                                type="text"
                                name="firstname_create"
                                ngControl="firstname_create"
                                [(ngModel)]="createAccount.firstName"
                                id="firstname_create"
                                class="p2 mt1"
                                required
                                [ngClass]="{ error: createAccount.firstNameError }"
                            />
                            <div class="text-small-x pt2">{{ createAccount.firstNameError }}</div>
                        </div>
                        <div class="text-input-container pb1">
                            <label class="text-small-xx ml2" for="lastname_create">Last Name</label>
                            <input
                                type="text"
                                name="lastname_create"
                                ngControl="lastname_create"
                                [(ngModel)]="createAccount.lastName"
                                id="lastname_create"
                                class="p2 mt1"
                                required
                                [ngClass]="{ error: createAccount.lastNameError }"
                            />
                            <div class="text-small-x pt2">{{ createAccount.lastNameError }}</div>
                        </div>
                        <div class="text-input-container pb1">
                            <label class="text-small-xx ml2" for="lastname_create">New Password</label>
                            <input
                                type="password"
                                name="password"
                                ngControl="password"
                                [(ngModel)]="createAccount.passWord"
                                #cPassword="ngModel"
                                id="password"
                                class="p2 mt1"
                                minlength="8"
                                required
                                pattern="^(?=[\x21-\x7E]*[0-9])(?=[\x21-\x7E]*[A-Z])(?=[\x21-\x7E]*[a-z])(?=[\x21-\x7E]*[\x21-\x2F|\x3A-\x40|\x5B-\x60|\x7B-\x7E])[\x21-\x7E]{8,32}$"
                                [ngClass]="{ error: cPassword.touched && cPassword.invalid }"
                            />
                            <div
                                *ngIf="cPassword.touched && cPassword.invalid"
                                class="text-small-xx py1 pl2 password-error"
                            >The password does not meet minimum requirements.</div>
                            <div
                                *ngIf="cPassword.invalid"
                                class="password-requirements py2 px3"
                            >
                                <ul class="has-bullets pl2">
                                    <li class="text-small-xx font-regular pb2">Be a minimum of eight (8) characters in length.</li>
                                    <li class="text-small-xx font-regular">Contain both upper and lowercase characters (e.g. A-Z, a-z)</li>
                                    <li class="text-small-xx font-regular">Have at least one numerical character (e.g. 0-9)</li>
                                    <li class="text-small-xx font-regular">Have at least one special character (e.g. !@#$?~-_)</li>
                                </ul>
                            </div>
                        </div>
                        <div class="text-input-container pb3 mt1">
                            <label class="text-small-xx ml2" for="lastname_create">Confirm Password</label>
                            <input
                                type="password"
                                name="confirmpassword"
                                ngControl="confirmpassword"
                                [(ngModel)]="createAccount.confirmPassWord"
                                id="confirmpasswordpassword"
                                class="p2 mt1"
                                required
                                #cConfirmPassword="ngModel"
                                (ngModelChange)="checkPasswordMatch()"
                                [ngClass]="{ error: createAccount.confirmPassWordError }"
                            />
                            <div
                                *ngIf="cConfirmPassword.touched && createAccount.confirmPassWordError"
                                class="text-small-xx py1 pl2 password-error"
                            >
                                {{ createAccount.confirmPassWordError }}
                            </div>
                        </div>
                        <div class="password-error text-small-xx py1 pl2 mb2 full-width" style="max-width: 250px;" *ngIf="createAccount.accountCreationError">{{ createAccount.accountCreationError }}</div>
                        <button
                            class="full-width py3 mb6 mt2 font-bold text-small-x bg-color-blue mt1"
                            style="max-width: 250px;"
                            (click)="createUserAccount()"
                            [ngClass]="{ 'cursor-pointer': formValid }"
                            [disabled]="!formValid"
                        >
                            Activate
                        </button>
                        <div *ngIf="okToSSOToCoreApp" class="text-right cursor-pointer underline">
                            <div  (click)="proceedToCoreAppSSo()">I'll do it later ></div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="currentMode === AccountCreateModes.SkipForNow" class="view-3" [@showModal]>
                <h2 class="pb3">
                    Skip for now
                </h2>
                <p class="pb21">Enter your email address to proceed.</p>
                <div class="text-input-container pb1">
                    <input
                        type="text"
                        name="firstname_skip"
                        ngControl="firstname_skip"
                        [(ngModel)]="skipCreate.firstName"
                        placeholder="First Name"
                        id="firstname_skip"
                        class="p3"
                        required
                        [ngClass]="{ error: skipCreate.firstNameError }"
                    />
                    <div class="text-small-x pt2">{{ skipCreate.firstNameError }}</div>
                </div>
                <div class="text-input-container pb1">
                    <input
                        type="text"
                        name="lastname_skip"
                        ngControl="lastname_skip"
                        [(ngModel)]="skipCreate.lastName"
                        placeholder="Last Name"
                        id="lastname_skip"
                        class="p3"
                        required
                        [ngClass]="{ error: skipCreate.lastNameError }"
                    />
                    <div class="text-small-x pt2">{{ skipCreate.lastNameError }}</div>
                </div>
                <div class="text-input-container pb3">
                    <input
                        type="email"
                        name="emailaddr_skip"
                        ngControl="emailaddr_skip"
                        [(ngModel)]="skipCreate.emailAddress"
                        placeholder="Email"
                        id="emailaddr_skip"
                        class="p3"
                        required
                        [ngClass]="{ error: skipCreate.emailError }"
                    />
                    <div class="text-small-x pt2" *ngIf="skipCreate.emailError">
                        {{ skipCreate.emailError }}
                    </div>
                </div>
                <div>{{ skipCreate.accountCreationError }}</div>
                <div class="pb3">
                    <button
                        (click)="createUserWithTempPasswordToSkip()"
                        [ngClass]="{'create-account-button': true}">
                        CONTINUE
                    </button>
                </div>
                <div>
                <a href="javascript:void()" (click)="showHello()">< Go Back</a></div>
            </div>
            <div *ngIf="currentMode === AccountCreateModes.Thanks" class="view-5 pt10">
                <div class="pb15">
                    <div class="flex flex-justify-center pb2">
                        <img src="/assets/images/showcase-logo-red-bold.svg"/>
                    </div>
                    <p class="text-center">Modern features to simplify your workflow</p>
                </div>
                <div class="pb15">
                    <h1 class="text-center text-large-xx pb4">Your account is active and your settings have been copied over!</h1>
                    <div class="flex flex-justify-center pb3">
                        <button class="text-center explore-btn py3 px4" (click)="exploreButtonAction()">Explore Showcase</button>
                    </div>
                    <p class="text-center text-small">Or go to <span class="internal-link" (click)="navigateHome()">{{baseUrl}}</span></p>
                </div>
                <div class="px11">
                    <h2 class="text-center pb2">Training Resources</h2>
                    <div class="flex flex-justify-center flex-align-center pb1">
                        <img src="/assets/images/webcast-overview-icon.png" class="pr3 icon">
                        <p class="flex-1 font-bold pr7">Webcast Overview</p>
                        <div class="flex">
                            <a class="pr1 resource-link" [href]="taillightBaseUrl+'/learning-center?training=true'" target="_blank">
                                Register Now
                            </a>
                            <img src="/assets/images/chevron-blue.svg">
                        </div>
                    </div>
                    <div class="flex flex-justify-center flex-align-center">
                        <img src="/assets/images/video-icon.png" class="pr3 icon">
                        <p class="flex-1 font-bold pr7">Online Resources</p>
                        <div class="flex">
                            <a class="pr1 resource-link" [href]="taillightBaseUrl+'/learning-center'" target="_blank">
                               View Videos
                            </a>
                            <img src="/assets/images/chevron-blue.svg">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <footer class="footer-container full-width py7">
        <div class="footer-inner-container flex px8">
            <div>
                <a [href]="taillightBaseUrl" target="_blank"><div class="logo"></div></a>
            </div>
            <div class="text-right flex-1">
                <p class="pb2">I'm not ready for change</p>
                <a [href]="fordProtectLoginHelp" target="_blank">{{currentMode === AccountCreateModes.Thanks ? 'Continue to ProfitLink' : "I'll do this later"}}</a>
            </div>
        </div>
    </footer>
</div>
