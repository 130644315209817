import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { WebServiceError } from '../../models/web.service.error';
import { RESTServiceClient } from '../utilities/rest.client';
import { SessionVariablesUtility } from '../utilities/session.variables';
import { BaseIamService } from './base.iam';
import { DealershipModel } from '../../models/dealership';
import { LegacyLink } from '../../models/base.model';
import { TLApplication } from '../../models/tlapplication';
import { Config } from '../config';
import { map } from 'rxjs/operators';
import {PORTAL_SUPPORTED_PROVIDERS} from '../../services/identity-am/providers';
import {TLAPPLICATIONS} from '../../services/identity-am/tlapplication';

const USER_RECENT_DEALERS_KEY = 'usr_recent_dealers_key';
const USER_LAST_DEALER_KEY = 'usr_last_dealer_key';

@Injectable()
export class DealershipService extends BaseIamService {
    /**
     * @constructor
     * @param http The HTTP service to use when communicating with the web service.
     */
    constructor(
        private restClientSvc: RESTServiceClient,
        sessionSvc: SessionVariablesUtility,
        config: Config) {
        super(sessionSvc, config);
    }
    private getDealerList(u): Observable<any> {
        return this.restClientSvc.get<Array<DealershipModel>>(u,
            this.defaultHeaders());
    }

    public getDealerById(id): Observable<any> {
        return this.restClientSvc.get<DealershipModel>(`${this.baseUrl}/dealerships/${id}`,
            this.defaultHeaders());
    }

    private filterDealer(d): boolean {
        return d.ProviderLinkIds != null && d.ProviderLinkIds.length > 0
                                &&
                                this.checkProviderDealerOk(d);
    }

    public searchDealers(nameOrAccount: string): Observable<Array<DealershipModel>> {
        const provid = this.sessionSvc.getCurrentUserProviderId();
        let u = nameOrAccount ? `${this.baseUrl}/dealerships/legalname/${nameOrAccount}?providerid=${provid}&pagenum=0&pagesize=200` : `${this.baseUrl}/dealerships/filters?providerid=${provid}&pagenum=0&pagesize=200`;
        return (new Observable<Array<DealershipModel>>(observer => {
            this.getDealerList(u)
            .subscribe(
                (resp: any) => {
                    const items = resp.ItemList || resp;
                    let results = [];
                    if (( items || []).length === 0 && nameOrAccount) {
                        u = `${this.baseUrl}/dealerships/filters?provaccountcode=${nameOrAccount}&providerid=${provid}`;
                        this.getDealerList(u)
                        .subscribe(
                            (respnew: any) => {
                                const itemsnew = respnew;
                                results = itemsnew.filter(dlr => this.filterDealer(dlr) )
                                .sort(
                                    (a, b) => a.LegalName.localeCompare(b.LegalName)
                                ).slice(0, itemsnew.length > 100 ? 100 : itemsnew.length);
                            observer.next(results);
                            observer.complete();
                        }
                        );
                    } else {
                        results = items.filter(dlr => this.filterDealer(dlr) )
                            .sort(
                                (a, b) => a.LegalName.localeCompare(b.LegalName)
                            ).slice(0, items.length > 100 ? 100 : items.length);
                        observer.next(results);
                        observer.complete();
                    }
                }
            ); }));
    }

    public getAllDealerships(): Observable<Array<DealershipModel>> {
        const u = `${this.baseUrl}/dealerships`;
        return (new Observable<Array<DealershipModel>>(observer => {
            this.getDealerList(u)
            .subscribe(
                (resp: any) => {
                    const items = resp.ItemList || resp;
                    let results = [];
                    results = items.filter(dlr => this.filterDealer(dlr) )
                        .sort(
                            (a, b) => a.LegalName.localeCompare(b.LegalName)
                        );
                        observer.next(results);
                        observer.complete();
                }
            ); }));
    }

    public getUserDealerships(providerId: string, userId: string): Observable<Array<DealershipModel>> {
        return this.restClientSvc.get<Array<DealershipModel>>(`${this.baseUrl}/dealerships/provider/${providerId}/useraccounts/${userId}`,
                this.defaultHeaders())
                .pipe(
                    map(
                        (response: Array<DealershipModel>) => {
                            return response
                                .filter((dlr) => this.checkProviderDealerOk(dlr))
                                .sort((a, b) => a.LegalName.localeCompare(b.LegalName));
                        }
                    )
                );
    }

    public getMenuProConvertedDealer(menuProDid: number): Observable<DealershipModel> {
        return this.restClientSvc.get<DealershipModel>(
            `${this.baseUrl}/dealerships/legacyapp/MenuPro/${menuProDid}`, this.defaultHeaders());
    }

    public getRistkenCoreConvertedDealer(rcOrgId: number): Observable<DealershipModel> {
        return this.restClientSvc.get<DealershipModel>(
            `${this.baseUrl}/dealerships/legacyapp/Ristken.Core/${rcOrgId}`, this.defaultHeaders());
    }

    private isAppOmitted = (omitApps: string[], app: TLAPPLICATIONS) =>
        (omitApps || []).find((omitApp) => omitApp === app)

    private hasApplication = (dealershipApps: TLApplication[], app: TLAPPLICATIONS): boolean =>
        Boolean(dealershipApps.find((tlApp: TLApplication) => tlApp.ApplicationName.toUpperCase() === app))

    private suppressCommerceSPANLink = (providerName: string, dealershipApps: TLApplication[]) => {
        const hideCommerce = this.hasApplication(dealershipApps, TLAPPLICATIONS.COMMERCE_HIDE);
        return providerName === 'FORDESP' || providerName === 'FORDESC' || hideCommerce;
    }

    public availableApplications = (
        dealership: DealershipModel,
        allApps: TLApplication[],
        omitApps: string[],
        providerCommerceMode: string
    ): string[] => {
        const availApps: string[] = [];
        const currProvider = this.sessionSvc.getProviderName();
        this.sessionSvc.setExpirationDate('');
        const dealershipApps: TLApplication[] =
            dealership.EnabledApplications.map((appId: string) => allApps.find((tlApp: TLApplication) => tlApp.Id === appId));

        if (this.hasApplication(dealershipApps, TLAPPLICATIONS.ENROLLMENT)) {
            availApps.push(TLAPPLICATIONS.ENROLLMENT);
        }

        if (
            this.hasApplication(dealershipApps, TLAPPLICATIONS.MENUPRO) &&
            !this.isAppOmitted(omitApps, TLAPPLICATIONS.MENUPRO)
        ) {
            availApps.push(TLAPPLICATIONS.MENUPRO);
        }

        if (
            !this.suppressCommerceSPANLink(currProvider, dealershipApps) &&
            dealership.LegacyLinks.find((lnk: LegacyLink) => lnk.LegacyApplication.toUpperCase() === TLAPPLICATIONS.SPAN)
        ) {
            if (providerCommerceMode === 'span-only') {
                availApps.push(TLAPPLICATIONS.SPAN);
            } else if (!this.isAppOmitted(omitApps, TLAPPLICATIONS.COMMERCE)) {
                availApps.push(TLAPPLICATIONS.COMMERCE);
            }
        }

        if (dealership.LegacyLinks.find((lnk: LegacyLink) => lnk.LegacyApplication.toUpperCase() === TLAPPLICATIONS.RISTKEN_CORE) != null) {
            if (this.hasApplication(dealershipApps, TLAPPLICATIONS.TRACKING)) {
                availApps.push(TLAPPLICATIONS.TRACKING);
            }
        }

        const allowShowcase = !this.isAppOmitted(omitApps, TLAPPLICATIONS.TLSHOWCASE);
        if (this.hasApplication(dealershipApps, TLAPPLICATIONS.TLSHOWCASE)) {
            if (currProvider === PORTAL_SUPPORTED_PROVIDERS.ZURICH) {
                this.sessionSvc.setExpirationDate('ACTIVE SUBSCRIPTION');
            }
            availApps.push(TLAPPLICATIONS.TLSHOWCASE);
        } else {
            const tlShowCaseDates = allApps.filter((x: TLApplication) => {
                return x.ApplicationName.toUpperCase().includes(TLAPPLICATIONS.TLSHOWCASE + '_'); } );
            if (tlShowCaseDates) {
                let isValidDate = null;
                const dealershipEnabledApps: TLApplication[] = [];
                // If we have more than one TailLightShowCase_
                tlShowCaseDates.forEach(rec => {
                    if (dealership.EnabledApplications.find((x: string) => x === rec.Id) != null) {
                        dealershipEnabledApps.push(rec);
                    }
                });
                let latestDate = null;
                if (dealershipEnabledApps.length > 0) {
                    // If the dealership has more than one TailLightShowCase_ ids enabled we look for the latest date
                    dealershipEnabledApps.forEach(rec => {
                        const tempDate = this.getDate(
                            rec.ApplicationName.substring(rec.ApplicationName.length - 6, rec.ApplicationName.length));
                        // Verifying that the TailLightShowCase_YYMMDD is a date
                        if (tempDate instanceof Date && !isNaN(tempDate.getTime())) {
                            if (latestDate) {
                                if (tempDate > latestDate) {
                                    latestDate = tempDate;
                                }
                            } else {
                                latestDate = tempDate;
                            }
                        }
                    });
                    // If the date is greater or equal to today, then the dealership has access to ShowCase
                    isValidDate = this.checkDate(latestDate);
                    if (isValidDate && allowShowcase) {
                        if (currProvider === PORTAL_SUPPORTED_PROVIDERS.ZURICH) {
                            this.sessionSvc.setExpirationDate(
                                ' (Access Ends: ' + (latestDate.getMonth() + 1) + '/' + latestDate.getDate() + ')');
                        }
                        availApps.push(TLAPPLICATIONS.TLSHOWCASE);
                    } else if (allowShowcase) {
                        if (currProvider === PORTAL_SUPPORTED_PROVIDERS.ZURICH) {
                            this.sessionSvc.setExpirationDate(' Subscription Required');
                        }
                        availApps.push(TLAPPLICATIONS.TLSHOWCASE);
                    }
                }
            }
        }

        if (currProvider === PORTAL_SUPPORTED_PROVIDERS.ZURICH) {
            const reOrderedAvailApps: string[] = [];
            if (availApps.find((appName: string) => appName === TLAPPLICATIONS.ENROLLMENT)) {
                reOrderedAvailApps.push(TLAPPLICATIONS.ENROLLMENT);
            }
            if (availApps.find((appName: string) => appName === TLAPPLICATIONS.TLSHOWCASE)) {
                reOrderedAvailApps.push(TLAPPLICATIONS.TLSHOWCASE);
            }
            if (availApps.find((appName: string) => appName === TLAPPLICATIONS.TRACKING)) {
                reOrderedAvailApps.push(TLAPPLICATIONS.TRACKING);
            }
            if (availApps.find((appName: string) => appName === TLAPPLICATIONS.MENUPRO)) {
               reOrderedAvailApps.push(TLAPPLICATIONS.MENUPRO);
            }
            if (availApps.find((appName: string) => appName === TLAPPLICATIONS.COMMERCE)) {
               reOrderedAvailApps.push(TLAPPLICATIONS.COMMERCE);
            }
            return reOrderedAvailApps;
        }

        return availApps;
    }

    public addUserAccount(dealershipId: string, userAccountId: string): Observable<DealershipModel> {
        return this.restClientSvc.post<DealershipModel>(`${this.baseUrl}/dealerships/${dealershipId}/useraccounts/${userAccountId}`,
                {}, // no body necessary
                this.defaultHeaders());
    }

    public removeUserAccount(dealershipId: string, userAccountId: string): Observable<DealershipModel> {
        return this.restClientSvc.del(`${this.baseUrl}/dealerships/${dealershipId}/useraccounts/${userAccountId}`,
                this.defaultHeaders());
    }

    public getUserRecentDealerships(maxCount: number): Array<string> {
        let recentDealerIds: string[] = [];
        // got to make sure it is related to the logged in user
        const recentSavedIds = localStorage.getItem(USER_RECENT_DEALERS_KEY + '+' + this.sessionSvc.getCurrentAuthInfo().Id);

        recentDealerIds = (recentSavedIds) ? recentSavedIds.split(';').reverse().slice(0, maxCount) : null;

        return recentDealerIds;
    }

    public getUserLastDealership(): string {
        // got to make sure it is related to the logged in user
        return localStorage.getItem(USER_LAST_DEALER_KEY + '+' + this.sessionSvc.getCurrentAuthInfo().Id);
    }

    public setRecentDealership(dlr: string): void {
        // got to make sure it is related to the logged in user
        localStorage.setItem(USER_LAST_DEALER_KEY + '+' + this.sessionSvc.getCurrentAuthInfo().Id, dlr);
        return;
    }

    public addRecentDealership(dealershipId: string, maxCount: number): void {
        // got to make sure it is related to the logged in user
        const recentSavedIds = localStorage.getItem(USER_RECENT_DEALERS_KEY + '+' +
            this.sessionSvc.getCurrentAuthInfo().Id) ? localStorage.getItem(USER_RECENT_DEALERS_KEY + '+' +
                this.sessionSvc.getCurrentAuthInfo().Id).split(';') : [];
        if (recentSavedIds.find((x: string) => x === dealershipId) != null) {
            return;
        }
        recentSavedIds.push(dealershipId);
        const savingIds = recentSavedIds.reverse().slice(0, maxCount);

        localStorage.setItem(USER_RECENT_DEALERS_KEY + '+' + this.sessionSvc.getCurrentAuthInfo().Id, savingIds.join(';'));

        return;
    }

    private checkDealerFilter(dlr: DealershipModel, dealername: string): boolean {
        if (!dealername) { return true; }

        return (dlr.LegalName.toUpperCase().indexOf(dealername.toUpperCase()) >= 0) ||
            ((dlr.ProviderLinkIds || []).find((lnk) => lnk.ProviderAccountCode.toUpperCase() === dealername.toUpperCase()) != null);
    }

    private checkProviderDealerOk(dlr: DealershipModel): boolean {
        const currProvider = this.sessionSvc.getProviderName();
        const currProviderId = this.sessionSvc.getCurrentUserProviderId();
        if (currProvider === PORTAL_SUPPORTED_PROVIDERS.ZURICH) {
            let ok = false;
            dlr.ProviderLinkIds.forEach((lnk: any) => {
                if (lnk.ProviderId === currProviderId &&
                    lnk.ProviderAccountCode.toUpperCase().indexOf('BPA') >= 0) {
                    ok = true;
                }
            });
            return ok;
        }else if (currProvider !== 'TAILLIGHT-NOPROVIDER') { // not a standalone dealer
            return (dlr.ProviderLinkIds ||[]).length > 0; // we have an account code for this dealership
        }
        return true;
    }

    private getDate(dateString): Date {
        const year = 20 + dateString.substring(0, 2);
        const month = dateString.substring(2, 4);
        const day = dateString.substring(4);
        const newDate = new Date(year, month - 1, day);
        return newDate;
    }

    private checkDate(latestDate): Boolean {
        const todaysDate = new Date();
        todaysDate.setHours(0, 0, 0, 0);
        if (latestDate >= todaysDate) {
            return true;
        }
        return false;
    }

}
