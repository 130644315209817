import {Injectable} from '@angular/core';
import { SessionVariablesUtility } from '../utilities/session.variables';
import { HttpHeaders } from '@angular/common/http';
import { Config } from '../config';

@Injectable()
export class BaseIamService {
    baseUrl: string;

    constructor(
        protected sessionSvc: SessionVariablesUtility,
        protected config: Config
    ) {
        this.baseUrl = config.get('iamApiEndpoint');
    }

    protected defaultHeaders = (iamToken: string = ''): HttpHeaders =>
        new HttpHeaders({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': this.sessionSvc.getCurrentUserToken() || iamToken,
        })
}
