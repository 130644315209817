import { Injectable, Optional, ValueProvider } from '@angular/core';

import * as _ from 'lodash';

export class ConfigData {

}

@Injectable()
export class Config {

    apiUrl: string;
    emailTemplates: any;

    constructor(@Optional() config: ConfigData) {
        _.assign(this, config);
    }

    public get(path): any {
        return _.result(this, path, () => { throw new Error(`path ${path} not found in configuration`) });
    }

    static forRoot(config): ValueProvider {
        return { provide: ConfigData, useValue: config }
    }
}
