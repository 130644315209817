import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {RESTServiceClient} from '../utilities/rest.client';
import {SessionVariablesUtility} from '../utilities/session.variables';
import {BaseIamService} from './base.iam';
import {ProviderModel} from '../../models/provider';
import { Config } from '../config';

export const PORTAL_SUPPORTED_PROVIDERS = {
    ZURICH: 'ZURICH',
    ALLY: 'ALLY',
    EASYCARE: 'EASYCARE',
    FORDESP: 'FORDESP',
    FORDESC: 'FORDESC',
    EFG: 'EFG'
};

@Injectable()
export class ProviderService extends BaseIamService {
    /**
     * @constructor
     * @param http The HTTP service to use when communicating with the web service.
     */
    constructor(
        private restClientSvc: RESTServiceClient,
        sessionSvc: SessionVariablesUtility, config: Config) {
            super(sessionSvc, config);
    }

    public getAllProviders(): Observable<Array<ProviderModel>>{
        return this.restClientSvc.get<Array<ProviderModel>>(`${this.baseUrl}/providers`,
                this.defaultHeaders());
    }

    public getProviderByCode(code: string): Observable<ProviderModel>{
        return this.restClientSvc.get<ProviderModel>(`${this.baseUrl}/providers/providercode/${code}`,
                this.defaultHeaders());
    }
}
