import { Observable, of } from 'rxjs';
import { map, concatMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Config } from '../config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import * as parser from 'xml2js';
import * as pathParser from 'xml2js-xpath';

const soapAuthRequest = (appUsr: string, appPwd: string) => {
    return `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
        xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
        <soap:Header>
            <ER_SoapAuthHeader xmlns="http://services.ristken.com">
            <AuthenticationUserName></AuthenticationUserName>
            <AuthenticationPassword></AuthenticationPassword>
            </ER_SoapAuthHeader>
        </soap:Header>
      <soap:Body>
        <CheckCoreAppsAuthentication xmlns="http://services.ristken.com">
          <userName>${appUsr}</userName>
          <passWord>${appPwd}</passWord>
        </CheckCoreAppsAuthentication>
      </soap:Body>
    </soap:Envelope>`;
};

const soapCoreUserInfoRequest = (encUserName: string, appName: string, authTok: string) => {
    return `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
        xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
        <soap:Header>
            <ER_SoapAuthHeader xmlns="http://services.ristken.com">
            <AuthenticationUserName></AuthenticationUserName>
            <AuthenticationPassword>${authTok}</AuthenticationPassword>
            </ER_SoapAuthHeader>
        </soap:Header>
      <soap:Body>
        <GetCoreUserInfo xmlns="http://services.ristken.com">
            <encryptedUName>${encUserName}</encryptedUName>
            <originalApp>${appName}</originalApp>
        </GetCoreUserInfo>
      </soap:Body>
    </soap:Envelope>`;
};
const buildSoapRequestOptions = (soapAction: string): any => {
    const reqOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'text/xml',
            'SOAPAction': soapAction
        }),
        responseType: 'text'
    };
    return reqOptions;
};

const soapCoreAppAuthRequest = (appUsr: string, appPwd: string, http: HttpClient, config: Config): Observable<any> => {
    return http.post(
        config.get('erateSignupUrl'),
        soapAuthRequest(appUsr, appPwd),
        buildSoapRequestOptions('http://services.ristken.com/CheckCoreAppsAuthentication')
    );
};

const soapCoreAppUserInfo = (encUserName: string, token: string, appName: string, http: HttpClient, config: Config): Observable<any> => {
    return http.post(
        config.get('erateSignupUrl'),
        soapCoreUserInfoRequest(encUserName, appName, token),
        buildSoapRequestOptions('http://services.ristken.com/GetCoreUserInfo')
    );
};

const parseXmlToJson = (xml: string): Observable<any> => {
    return (new Observable<string>(observer => {
        parser.parseString(xml,
            (err: any, res: any) => {
                !!err ? observer.error(err) : observer.next(res);
                observer.complete();
            }
        );
    }));
};

const callCoreAppCheckToJson = (userName: string, userPwd: string, http: HttpClient, config: Config): Observable<any> => {
    return soapCoreAppAuthRequest(
        userName, userPwd,
        http, config
    )
        .pipe(
            concatMap((text) => {
                return parseXmlToJson(text);
            }),
            catchError((err) => of(err))// BadRequest not ok
        );
};

const callCoreUserInfoToJson = (userName: string, token: string, appName: string, http: HttpClient, config: Config): Observable<any> => {
    return soapCoreAppUserInfo(
        userName, token, appName,
        http, config
    )
        .pipe(
            concatMap((text) => {
                return parseXmlToJson(text);
            }),
            catchError((err) => of(err))// BadRequest not ok
        );
};

const parseJsonWithXPath = (jsonObj: any, xpath: string): any => {
    return pathParser.find(jsonObj, xpath) ? pathParser.find(jsonObj, xpath)[0] : null;
};

@Injectable()
export class CoreAppAuthenticationService {
    constructor(
        private config: Config,
        private http: HttpClient) {
    }

    public checkCoreAppsAuthentication = (userName: string, userPwd: string): Observable<any> => {
        return callCoreAppCheckToJson(userName, userPwd, this.http, this.config)
            .pipe(
                map(
                    (ret) => {
                        return {
                            ristkenCoreAuthSuccess: parseJsonWithXPath(ret, '//RistkenCoreAuthenticationSuccessful') === 'true',
                            ristkenCoreOrgId: parseJsonWithXPath(ret, '//RistkenCoreAppDealershipId'),
                            ristkenCoreUserId: parseJsonWithXPath(ret, '//RistkenCoreUserId'),
                            ristkenCoreUserEnc: parseJsonWithXPath(ret, '//RistkenCoreEncryptedUName'),
                            menuProAuthSuccess: parseJsonWithXPath(ret, '//MenuProAuthenticationSuccessful') === 'true',
                            menuProDealerId: parseJsonWithXPath(ret, '//MenuProAppDealershipId'),
                            message: parseJsonWithXPath(ret, '//FailMessage'),
                            provider: parseJsonWithXPath(ret, '//ProviderName'),
                            firstName: parseJsonWithXPath(ret, '//UserFirstName'),
                            lastName: parseJsonWithXPath(ret, '//UserLastName'),
                            menuToken: parseJsonWithXPath(ret, '//MenuProToken')
                        };
                    }
                )
            );
    }

    public getCoreAppUserInfo = (encUserName: string, appName: string, token: string): Observable<any> => {
        return callCoreUserInfoToJson(encUserName, token, appName, this.http, this.config)
            .pipe(
                map(
                    (ret) => {
                        return {
                            ristkenCoreAuthSuccess: parseJsonWithXPath(ret, '//RistkenCoreAuthenticationSuccessful') === 'true',
                            ristkenCoreOrgId: parseJsonWithXPath(ret, '//RistkenCoreAppDealershipId'),
                            ristkenCoreUserId: parseJsonWithXPath(ret, '//RistkenCoreUserId'),
                            ristkenCoreUserEnc: parseJsonWithXPath(ret, '//RistkenCoreEncryptedUName'),
                            menuProAuthSuccess: parseJsonWithXPath(ret, '//MenuProAuthenticationSuccessful') === 'true',
                            menuProDealerId: parseJsonWithXPath(ret, '//MenuProAppDealershipId'),
                            message: parseJsonWithXPath(ret, '//FailMessage'),
                            provider: parseJsonWithXPath(ret, '//ProviderName'),
                            firstName: parseJsonWithXPath(ret, '//UserFirstName'),
                            lastName: parseJsonWithXPath(ret, '//UserLastName'),
                            menuToken: parseJsonWithXPath(ret, '//MenuProToken')
                        };
                    }
                )
            );
    }
}
