import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {RESTServiceClient} from '../utilities/rest.client';
import {SessionVariablesUtility} from '../utilities/session.variables';
import {BaseIamService} from './base.iam';
import { Config } from '../config';
import { map } from 'rxjs/operators';

@Injectable()
export class EmailService extends BaseIamService {
    constructor(
        private restClientSvc: RESTServiceClient,
        sessionSvc: SessionVariablesUtility,
        config: Config) {
            super(sessionSvc, config);
    }

    public sendEmail(emailTo: string, emailFrom: string, emailSubject: string, emailBody: string): Observable<boolean> {
        return this.restClientSvc.post<string>(`${this.baseUrl}/email`,
            {
                From : emailFrom,
                To : emailTo,
                Subject : emailSubject,
                Body : emailBody,
                Format : 'html'
            },
            this.defaultHeaders())
            .pipe(
                map(
                    (response) => {
                        return response === 'Success';
                    }
                )
            );
    }
}
