<div class="main-container">
    <div class="navbar-container">
        <div class="navbar-inner-container">
            <a href="https://www.taillight.com/" target="_blank"><div class="logo"></div></a>
            <form *ngIf="!fordProtectLogin" class="form" (ngSubmit)="onSubmit()" #loginForm="ngForm">
                <div class="text-input-container">
                    <input
                        type="text"
                        name="userName"
                        ngControl="userName"
                        [(ngModel)]="userName"
                        placeholder="Email"
                        id="userName"
                        class="text-input"
                        (keydown)="onEditChange()"
                        required
                        [ngClass]="{ error: loginError }"
                    />
                    <div class="input-message error-message" *ngIf="loginError">
                        {{ loginError }}
                    </div>
                </div>
                <div class="text-input-container">
                    <input
                        type="password"
                        name="password"
                        ngControl="password"
                        [(ngModel)]="password"
                        placeholder="Password"
                        id="password"
                        class="text-input"
                        (keydown)="onEditChange()"
                        required
                        [ngClass]="{ error: loginError }"
                    />
                </div>
                <div>
                    <button
                        [disabled]="!loginForm.form.valid"
                        type="submit"
                        [ngClass]="{'login-button': true, 'valid': loginForm.form.valid}"
                    >
                        Login
                    </button>
                </div>
            </form>
        </div>
    </div>

    <div class="main-content-container">
        <div *ngIf="fordProtectLogin">
            <form class="form flex flex-column" (ngSubmit)="onSubmit()" #loginFormFP="ngForm">
                <img class="full-width" src="../../../assets/images/ford-profitlink-logo.png"/>
                <div class="full-width py8 flex-column ford-login-background flex-justify-center flex-align-center">
                    <div class="ford-login-input-container pb1">
                        <label class="text-small-xx color-white pl2" for="userNameFP">User ID</label>
                        <input
                            type="text"
                            name="userNameFP"
                            ngControl="userNameFP"
                            [(ngModel)]="userNameFP"
                            id="userNameFP"
                            class="text-input mt1"
                            (keydown)="onEditChangeFP()"
                            required
                            [ngClass]="{ error: loginErrorFP }"
                        />

                    </div>
                    <div class="ford-login-input-container pb2">
                        <label class="text-small-xx color-white pl2" for="passwordFG">Password</label>
                        <input
                            type="password"
                            name="passwordFP"
                            ngControl="passwordFP"
                            [(ngModel)]="passwordFP"
                            id="passwordFP"
                            class="text-input mt1"
                            (keydown)="onEditChangeFP()"
                            required
                            [ngClass]="{ error: loginErrorFP }"
                        />
                    </div>
                    <div class="ford-login-error-message py1 px10 my2" *ngIf="loginErrorFP">
                        {{ loginErrorFP }}
                    </div>
                    <button
                        [disabled]="!loginFormFP.form.valid"
                        type="submit"
                        class="ford-login-input-container text-small-x mt3 mb5 cursor-pointer"
                        [ngClass]="{'ford-login-button': true, 'valid': loginFormFP.form.valid}">
                        Login
                    </button>

                    <div>
                        <a class="text-small-x color-white font-regular" href="{{fordProtectUrl}}" target="_blank">Need help logging in?</a>
                    </div>
                </div>
            </form>
        </div>
        <div style="width: 500px!important; line-height: 25px;">
            <div>
                <p style="text-align: center; color: #ffffff">
                    <span style="font-size: 20px; font-weight: bold; text-decoration: underline;">This website has been updated</span><br><br>
                    Please go to following website and use your current ID/PW:<br><br>
                    <img src="/assets/images/PowerTracking-RedWht.png" width="300"><br>
                    <a href="https://reporting.taillight.com" style="font-size: 16px; color: silver; position: relative; top: -10px!important">https://reporting.taillight.com</a><br><br>
                    PLEASE UPDATE/ ADD THIS NEW PAGE TO YOUR FAVORITES<br><br>
                    Your username is your email address.  If you have forgotten your <br>
                    password, please use the password <a href="https://reporting.taillight.com/Login.aspx" style="color: gray;">reset link.</a><br>
                    If you experience any login issues, please contact us directly at<br>
                    <a href="mailto:tlsupport@darwinautomotive.com" style="color: silver">tlsupport@darwinautomotive.com</a> or call (972)991-6301.
                </p>
            </div>
        </div>
    </div>

    <div class="copyright-text">© {{year}} Tail Light • All Rights Reserved</div>

    <div
        class="modal-container"
        [ngClass]="{ active: modalActive }"
        id="forgotPasswordModal"
    >
    <div (click)="toggleModal()" class="close-modal-button">
        ✕
    </div>
        <div class="modal-content">
            <app-forgot-password
                *ngIf="modalActive"
                [emailAddress]="userName || userNameFP"
                [type]="passwordResetType"
            ></app-forgot-password>
        </div>
    </div>
</div>
