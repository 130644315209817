import { Component, Input } from '@angular/core';
import { first } from 'rxjs/operators';

import { PasswordResetTypes, UserAccountService, emailRegExp } from '../../services/identity-am/user.account';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot.password.html',
    styleUrls: ['./forgot.password.scss']
})
export class ForgotPasswordComponent {
    @Input() emailAddress: string;
    @Input() type: PasswordResetTypes;

    public complete = false;
    public emailRegExp = emailRegExp;
    public PasswordResetTypes = PasswordResetTypes;

    constructor(
        private userAccountSvc: UserAccountService,
    ) { }

    onSubmit = (): void => {
        this.userAccountSvc.requestPasswordReset(this.emailAddress, this.type)
            .pipe(first())
            .subscribe(() => { this.complete = true; });
    }
}
