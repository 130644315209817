import { Component, OnInit, HostListener  } from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';
import { first } from 'rxjs/operators'
import * as _ from 'lodash';

import { UserAccount } from '../../models/user.account';

import { PasswordResetTypes, UserAccountService, emailRegExp } from '../../services/identity-am/user.account';
import { SessionVariablesUtility } from '../../services/utilities/session.variables';
import { DealershipService } from '../../services/identity-am/dealerships';
import { DealershipModel } from '../../models/dealership';
import { Claim } from '../../models/user.account';
import { Observable } from 'rxjs';

@Component({
    selector: 'account-management',
    templateUrl: './account.management.html',
    styleUrls: ['./account.management.scss'],
    animations: [
        trigger('expandableHeightAnimate', [
            transition(':enter', [
                style({maxHeight: 0}),
                animate('1000ms', style({maxHeight: '1000px'}))
            ]),
            transition(':leave', [
                style({maxHeight: '1000px'}),
                animate('1000ms', style({maxHeight: 0}))
            ])
        ])
    ]
})

export class AccountManagementComponent implements OnInit {
    CLAIM_TYPE_ROLE = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

    isPortalAdmin: boolean = false;
    //
    // admin function fields
    //
    searchText: string = '';

    searchUsers: Array<UserAccount>;

    selectedUser: string;
    createNewUser: boolean = false;

    chosenUser: UserAccount = null;
    chosenUserDealerships: Array<DealershipModel> = null;
    selectedAssocDealer: string;

    availableDealerships: Array<DealershipModel> = null;
    selectedAvailDealer: string;

    userNameOK: any = true;
    initialPasswordOK: boolean = true;
    passwordLink: string = null;
    //
    // logged in portal user fields
    //
    portalUser: UserAccount = null;
    confirmPrtlPassword: string = null;
    prtlPasswordOK: boolean = true;
    prtlPasswordConfirmOK: boolean = true;
    prtlUserSvMessage: string;

    // UI vars
    // Form validation vars
    /* - Edit exisiting user vars - */
    isSearchingUser: boolean = false;
    isDeletingUser: boolean = false;
    showCurrentUserModal: boolean = false;
    deleteUserModalVisible: boolean = false;
    currentUserModalMessage: string = '';
    deleteUserModalMessage: string = '';
    showDeleteUserModalButtons: boolean = true;
    currentUserUpdating: boolean = false;
    currentUserClaimsOK : boolean = false;
    /* - Edit exisiting user vars - */
    /* - New user vars - */
    userRoleIsEmpty: boolean = false;
    selectedDealershipIsEmpty: boolean = false;
    emailFieldTouched: boolean = false;
    showNewUserModal: boolean = false;
    newUserModalMessage: string = '';
    newUserUpdating: boolean = false;
    searchFocused: boolean = false;
    /* - End new user vars - */

    emailRegExp = emailRegExp;

    constructor(
                private sessSvc: SessionVariablesUtility,
                private userAccountSvc: UserAccountService,
                private dealerSvc: DealershipService,
        ) {
        userAccountSvc.getUserById(sessSvc.getCurrentAuthInfo().Id)
            .subscribe(
                (user: UserAccount) => {
                    this.portalUser = user;
                    this.prtlPasswordConfirmOK = this.prtlPasswordOK = true;
                }
            );
    }

    ngOnInit(): any {
        // load the dealers available to this user
        this.loadAvailableDealerships();
        // determine if the logged in user is a portal admin
        this.isPortalAdmin = this.sessSvc.getUserIsPortalAdmin();
    }

    loadAvailableDealerships(): void {
        this.chosenUserDealerships = [];
        this.dealerSvc.getAllDealerships()
            .subscribe(
                (dealers: Array<DealershipModel>) => {
                    this.availableDealerships = dealers;
                }
            );
    }

    //
    //#region User Administration Component Functions
    //

    public onUserSearchChange = (): void => {
        this.isSearchingUser = true;

        this.userAccountSvc.search(this.searchText)
            .subscribe((users: UserAccount[]) => {
                this.searchUsers = users;
                this.isSearchingUser = false;

                if (users.length === 1) {
                    this.selectedUser = users[0].Id;
                    this.chosenUser = users[0];
                    this.loadUser(this.chosenUser.Id);
                }
            });
    }

    // select a user
    onUserChange(): any {
        this.chosenUser = this.searchUsers.find((user: UserAccount) => {
            return user.Id === this.selectedUser;
        });
        this.loadUser(this.chosenUser.Id);
    }

    // retrieve the user by id
    loadUser(userId): any {
        this.userAccountSvc.getUserById(userId)
            .subscribe(
                (user: UserAccount) => {
                    this.chosenUser = user;
                    this.currentUserClaimsOK = this.checkChosenUserAnyClaim();
                }
            );

        this.dealerSvc.getUserDealerships(
            this.sessSvc.getCurrentUserProviderId(),
            userId
        ).subscribe(
            (dlrs: Array<DealershipModel>) => {
                this.chosenUserDealerships = dlrs;
                this.removeAssociatedDealershipsFromAvailableDealerships();
            }
        );

    }

    // add or remove a claim
    changeClaim(claimName: string): void {
        this.userRoleIsEmpty = false;

        if (!(this.chosenUser.Claims))
            this.chosenUser.Claims = [];

        let found = this.chosenUser.Claims && this.chosenUser.Claims.find((clm: Claim) => {
            return clm && clm.Value.toUpperCase() === claimName.toUpperCase()
        });

        if (found != null) {
            this.chosenUser.Claims = this.chosenUser.Claims.filter((claim: Claim) => {
                return claim.Value != found.Value;
            });
        } else {
            this.chosenUser.Claims.push({
               Type: this.CLAIM_TYPE_ROLE,
               Value: claimName
            });
        }
        this.currentUserClaimsOK = this.checkChosenUserAnyClaim();
    }

    // start a new empty user
    startNewUser($event) {
        this.clearUser();
        this.clearSearch();
        this.showCurrentUserModal = false;
        this.chosenUser = new UserAccount();
        this.createNewUser === true ? this.createNewUser = false : this.createNewUser = true;
    }

    // clear out the current user information
    clearUser(): void {
        this.loadAvailableDealerships();
        this.selectedUser = null;
        this.userNameOK = true;
        this.chosenUser = new UserAccount();
        this.chosenUser.Claims = new Array<Claim>();
        this.passwordLink = null;
        this.currentUserClaimsOK = false;
    }

    clearSearch(): void {
        this.searchUsers = null;
        this.selectedUser = null;
        this.searchText = '';
    }

    // add the dealership to the user
    addDealership(): void {
        const addingDealer = this.availableDealerships.filter((dlr: DealershipModel) => {
            return this.selectedAvailDealer.indexOf(dlr.Id) > -1;
        });
        const dealershipsToRemove = {};
        if (addingDealer != null) {
            addingDealer.forEach((dealerToAdd) => {
                this.chosenUserDealerships.push(dealerToAdd);
                dealershipsToRemove[dealerToAdd.Id] = true;
            });
        }
        // Remove chosen dealership from dropdown
        this.availableDealerships = this.availableDealerships.filter( dlr => !dealershipsToRemove[dlr.Id]);
        this.selectedAvailDealer = null;
    }

    // Remove the dealership from the user
    removeDealership(): void {
        const removingDealer = this.chosenUserDealerships.filter((usrDlr: DealershipModel) => {
            return this.selectedAssocDealer.indexOf(usrDlr.Id) > -1;
        });
        const dealershipsToReinsert = {};
        // Push removed dealerships back to Available Dealerships
        removingDealer.forEach((dlr) => {
            this.availableDealerships.unshift(dlr);
            dealershipsToReinsert[dlr.Id] = true;
        });
        // Remove dealerships from 'Dealerships Associated'
        this.chosenUserDealerships = this.chosenUserDealerships.filter( dlr => !dealershipsToReinsert[dlr.Id]);
        this.selectedAssocDealer = null;
    }

    checkUserNameOK = (): void => {
        this.emailFieldTouched = true;
        this.userAccountSvc.userNameAvailable(this.chosenUser.Email)
            .subscribe((ok: boolean) => {
                this.userNameOK = ok;
            });
    }

    public onEmailFieldFocus = () => {
        this.userNameOK = true;
    }

    getProviderAccountSearchFormat(providerName): string {
        switch (providerName.toUpperCase()) {
            case 'ZURICH' : {
                return 'BPA';
            }
            default : {
                return '';
            }
        }
    }

    // save the user information
    saveUser(): void {
        // Update UI vars
        this.newUserModalMessage = this.currentUserModalMessage = '';
        this.newUserUpdating = true;
        this.currentUserUpdating = true;
        this.showNewUserModal = true;
        this.showCurrentUserModal = true;

        const provName = this.sessSvc.getProviderName();
        let emailNew = false;
        if (!this.chosenUser.Id) {
            let createUser = this.userAccountSvc.buildNewUser(
                this.chosenUser.FirstName, this.chosenUser.LastName, this.chosenUser.Email,
                this.chosenUser.Claims.map((clm: Claim) => {
                    return clm.Value;
                }),
                this.chosenUser.Password,
                provName
            );
            this.chosenUser = createUser;
            emailNew = true;
        }

        const srchFormat = this.getProviderAccountSearchFormat(provName).toUpperCase();

        const dealerAccount = this.chosenUserDealerships.map((dealership:DealershipModel)=> {
            if ((srchFormat || '').length > 0){
                return dealership.ProviderLinkIds.find(
                    (lnk: any) => lnk.ProviderAccountCode.toUpperCase().indexOf(srchFormat) >=0).ProviderAccountCode;
            }else{
                return dealership.ProviderLinkIds[0].ProviderAccountCode;
            }
        });

        this.userAccountSvc.saveUser(this.chosenUser, dealerAccount, this.sessSvc.getCurrentUserProviderId())
            .subscribe(
                (user: UserAccount) => {
                    if( user ){
                        this.newUserModalMessage = this.currentUserModalMessage = 'Success';
                    } else if( !user ){
                        this.newUserModalMessage = this.currentUserModalMessage = 'Error';
                    }
                    this.chosenUser = user;

                    if (emailNew) {
                        this.sendPasswordChangeEmail();
                    }

                    this.newUserUpdating = this.currentUserUpdating = false;
                    let oldDealers = this.dealerSvc.getUserDealerships(this.sessSvc.getCurrentUserProviderId(), user.Id)
                        .subscribe(
                            (oldDealers: Array<DealershipModel>) => {
                                let addDealerships =
                                    this.chosenUserDealerships.filter((dealership: DealershipModel) => {
                                            return oldDealers.find((oldDealership: DealershipModel) => {
                                                    return oldDealership.Id === dealership.Id
                                                }) == null;
                                        }
                                    );

                                let rmvDealerships =
                                    oldDealers.filter((oldDealership: DealershipModel) => {
                                            return this.chosenUserDealerships.find((dealership: DealershipModel) => {
                                                    return dealership.Id === oldDealership.Id
                                                }) == null;
                                        }
                                    );

                                let dealerUpdate = new Observable(
                                    observer => {

                                        let items: number = 0;

                                        addDealerships.forEach(
                                            (dealership: DealershipModel) => {
                                                this.dealerSvc.addUserAccount(dealership.Id, user.Id)
                                                    .subscribe((dealership: DealershipModel) => {
                                                        items++;
                                                        if (items >= addDealerships.length + rmvDealerships.length) {
                                                            observer.complete();
                                                        } else {
                                                            observer.next(dealership);
                                                        }
                                                    });
                                            }
                                        );

                                        rmvDealerships.forEach(
                                            (dealership: DealershipModel) => {
                                                items++;
                                                this.dealerSvc.removeUserAccount(dealership.Id, user.Id)
                                                    .subscribe(
                                                        (dealership: DealershipModel) => {
                                                            items++;
                                                            if (items >= addDealerships.length + rmvDealerships.length) {
                                                                observer.complete();
                                                            } else {
                                                                observer.next(dealership);
                                                            }
                                                        });
                                            }
                                        );
                                    }
                                );

                                let sub = dealerUpdate.subscribe(
                                    val => {
                                        this.clearUser();
                                    },
                                    error => {
                                        this.clearUser();
                                    },
                                    () => {
                                        this.clearUser();
                                        this.loadUser(user.Id);
                                    }
                                );
                            }
                        );
                }
            );
    }

    // permanent deletion of user.. bwahahaha
    toggleDeleteUserModalVisible($event): void {
        $event.stopPropagation();
        if ($event.target.classList.contains('non-closing')) {
            return;
        }
        this.deleteUserModalVisible = !this.deleteUserModalVisible;
        if (this.deleteUserModalVisible) {
            this.deleteUserModalMessage = 'Are you sure you want to delete this user?';
            this.showDeleteUserModalButtons = true;
        }
    }

    deleteUser(): void {
        this.isDeletingUser = true;
        this.showDeleteUserModalButtons = false;
        let oldDealers = this.dealerSvc.getUserDealerships(this.sessSvc.getCurrentUserProviderId(), this.chosenUser.Id)
            .subscribe(
                //first we are going to remove the user from any dealership it is associated to
                (oldDealers: Array<DealershipModel>) => {
                    let dealerRemoveAll = new Observable(
                        observer => {

                            let items: number = 0;
                            oldDealers.forEach(
                                (dealership: DealershipModel) => {
                                    this.dealerSvc.removeUserAccount(dealership.Id, this.chosenUser.Id)
                                        .subscribe(
                                            (dealership: DealershipModel) => {
                                                items++;
                                                if (items >= oldDealers.length) {
                                                    observer.complete();
                                                } else {
                                                    observer.next(dealership);
                                                }
                                            })
                                }
                            );
                        }
                    );

                    if (oldDealers.length > 0){
                        let sub = dealerRemoveAll.subscribe(
                            val => {
                            },
                            error => {
                            },
                            () => {

                                this.userAccountSvc.deleteUserById(this.chosenUser.Id, true)
                                    .subscribe(
                                        (result: boolean) => {
                                            this.clearSearch();
                                            this.clearUser();
                                            this.isDeletingUser = false;
                                            if (result) {
                                                this.deleteUserModalMessage = 'You have successfully deleted this user.';
                                                //this.chosenUser = null;
                                            } else if (!result) {
                                                this.deleteUserModalMessage = 'Error deleting user.';
                                            }
                                        }
                                    )

                            }
                        );
                }else{
                    this.userAccountSvc.deleteUserById(this.chosenUser.Id, true)
                    .subscribe(
                        (result: boolean) => {
                            this.clearSearch();
                            this.clearUser();
                            this.isDeletingUser = false;
                            if (result) {
                                this.deleteUserModalMessage = 'You have successfully deleted this user.';
                            } else if (!result) {
                                this.deleteUserModalMessage = 'Error deleting user.';
                            }
                        }
                    );
                }
                }
            );
    }

    public isChosenUserClaim = (searchClaim: string): boolean =>
        this.chosenUser &&
        this.chosenUser.Claims &&
        Boolean(
            this.chosenUser.Claims.find((claim: Claim) => claim && claim.Value && claim.Value.toUpperCase() === searchClaim.toUpperCase())
        )

    checkChosenUserAnyClaim(): boolean {
        return this.chosenUser.Claims != null &&
            this.chosenUser.Claims.length > 0;
    }

    private sendPasswordChangeEmail = (): void => {
        this.userAccountSvc.requestPasswordReset(this.chosenUser.Email, PasswordResetTypes.CREATE)
            .pipe(first())
            .subscribe(() => {});
    }

    cancelUpdateUser(): void {
        this.clearSearch();
        this.clearUser();
        this.chosenUser = null;
    }

    cancelNewUser(): void {
        this.clearSearch();
        this.clearUser();
        this.chosenUser = null;
        this.createNewUser === true ? this.createNewUser = false : this.createNewUser = true;
    }

    // This function binds to an eventEmitter to toggle our value from the modal component
     hideModal($event){
        this.showNewUserModal = $event;
        this.showCurrentUserModal = $event;
        // this.clearSearch();
        // this.clearUser();
    }

    removeAssociatedDealershipsFromAvailableDealerships() {
        const chsnDlrshpIds = {};
        this.chosenUserDealerships.forEach((dlr) => { chsnDlrshpIds[dlr.Id] = true; });
        this.availableDealerships = this.availableDealerships.filter((dlr) => !chsnDlrshpIds[dlr.Id]);
    }

    // Handle when the user hits enter while the 'search' button is focused
    @HostListener('window:keyup', ['$event'])

    keyEvent(event: KeyboardEvent) {
        if (event.code && event.code === 'Enter') {
            if (this.searchFocused) {
                this.onUserSearchChange();
            }
        }
    }

    handleSearchFocus() {
        this.searchFocused = true;
    }

    handleSearchBlur() {
        this.searchFocused = false;
    }
}
