import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { RESTServiceClient } from '../utilities/rest.client';
import { SessionVariablesUtility } from '../utilities/session.variables';
import { BaseIamService } from './base.iam';
import { TLApplication } from '../../models/tlapplication';
import { Config } from '../config';

const USER_RECENT_APP_KEY = 'usr_recent_tlapp_key';

export enum TLAPPLICATIONS {
    MENUPRO = 'MENUPRO',
    TRACKING = 'TRACKING',
    COMMERCE = 'TAILLIGHTCOMMERCE',
    COMMERCE_HIDE = 'TAILLIGHTCOMMERCE_HIDE',
    OFFSITE = 'OFFSITE',
    SPAN = 'SPAN',
    TLSHOWCASE = 'TAILLIGHTSHOWCASE',
    RISTKEN_CORE = 'RISTKEN.CORE',
    ENROLLMENT = 'ENROLLMENT',
    SHOWCASE = 'SHOWCASE',
}

@Injectable()
export class TLApplicationService extends BaseIamService {
    constructor(
        private restClientSvc: RESTServiceClient,
        public sessionSvc: SessionVariablesUtility,
        public config: Config
    ) {
        super(sessionSvc, config);
    }

    public getTlSsoApplications = (): Observable<TLApplication[]> =>
        this.restClientSvc.get<TLApplication[]>(`${this.baseUrl}/rssapplications`, this.defaultHeaders())
            .pipe(first())

    public getUserLastApplication = (): string => localStorage.getItem(USER_RECENT_APP_KEY);

    public setRecentApplication = (appName: string): void => {
        localStorage.setItem(USER_RECENT_APP_KEY, appName);
    }
}
