import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, concatMap, first, map } from 'rxjs/operators';
import * as _ from 'lodash';

import { RESTServiceClient, IResult } from '../utilities/rest.client';
import { SessionVariablesUtility } from '../utilities/session.variables';
import { BaseIamService } from './base.iam';
import { UserAccount } from '../../models/user.account';
import { Claim } from '../../models/user.account';
import { Config } from '../config';

import { AuthenticationModel } from '../../models/user.auth';
import { LegacyLink } from '../../models/base.model';

const CLAIM_TYPE_ROLE = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

const LOGIN_TIMEOUT_MINUTES = 1440; // 24 hours

const passwordSeed = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L',
    'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a',
    'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p',
    'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '1', '2', '3', '4', '5',
    '6', '7', '8', '9', '0', '@', '!'];

export const emailRegExp = new RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/); // tslint:disable-line:max-line-length

export enum PasswordResetTypes {
    RESET = 'reset',
    CREATE = 'create',
}

@Injectable()
export class UserAccountService extends BaseIamService {
    constructor(
        private restClientSvc: RESTServiceClient,
        sessionSvc: SessionVariablesUtility,
        config: Config,
        private http: HttpClient) {
        super(sessionSvc, config);
    }

    private SignUpSPANReportingUser = (
        newUser: boolean,
        user: UserAccount,
        providerId: string,
        dealerAccounts: string[]
    ): Observable<string> => {
        const url = this.config.get('erateSignupUrl');
        let body = '<?xml version="1.0" encoding="utf-8"?><soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"><soap:Header><ER_SoapAuthHeader xmlns="http://services.ristken.com"><AuthenticationUserName></AuthenticationUserName><AuthenticationPassword></AuthenticationPassword></ER_SoapAuthHeader></soap:Header><soap:Body><SignupERatingUser xmlns="http://services.ristken.com"><_userSignup><CreationMode>{createmode}</CreationMode><UserName>{username}</UserName><Password>{password}</Password><SecretQuestion>username</SecretQuestion><SecretAnswer>{secretanswer}</SecretAnswer><FirstName>{firstname}</FirstName><MiddleName></MiddleName><LastName>{lastname}</LastName><EmailAddress>{email}</EmailAddress><ActiveStart>{start}</ActiveStart><ActiveEnd>{end}</ActiveEnd><PrimaryPhone></PrimaryPhone><Fax></Fax><UserRoleNames>{claims}</UserRoleNames><ProviderDealerUniqueID></ProviderDealerUniqueID><ProviderMultiDealerUniqueIDs>{dealeruniqueids}</ProviderMultiDealerUniqueIDs><ProviderUserName>{providerusername}</ProviderUserName><ProviderZones></ProviderZones><RistkenZones></RistkenZones><RistkenDealerID>0</RistkenDealerID></_userSignup></SignupERatingUser></soap:Body></soap:Envelope>'; // tslint:disable-line:max-line-length

        let oldUserId = null;
        if (user.LegacyLinks != null && user.LegacyLinks.find(
            (lnk: LegacyLink) => lnk.LegacyApplication.toUpperCase() === 'RISTKEN.CORE') != null) {
            oldUserId = user.LegacyLinks.find(
                (lnk: LegacyLink) => lnk.LegacyApplication.toUpperCase() === 'RISTKEN.CORE').LegacyTableId;
            newUser = false;
        }

        body = body.replace('{username}', oldUserId === null ? user.Email : oldUserId)
            .replace('{password}', newUser ? user.Password : '')
            .replace('{secretanswer}', 'l4Xj+7JFEkpC7Q0rdH3mvBX/SxbChGCRohoMi38MtXowx+Njz803mMmeL59QUcxylO1yf0iSbM8ePG4YLZRpw5IN2dlsJcIM1d+1Q+28SeoNcXHhy6bGe4B73Epc5KZwXmHOzmNazn7772XVcVVTSc9OBYGSfG0UcFfZb6BfynY=') // tslint:disable-line:max-line-length
            .replace('{firstname}', user.FirstName)
            .replace('{lastname}', user.LastName)
            .replace('{email}', user.Email)
            .replace('{start}', '1900-01-01')
            .replace('{end}', '2999-12-31')
            .replace('{providerusername}', 'CORE-PORTAL-WEB') // Letting sign up know not to run 'IM conversion'
            .replace('{createmode}', newUser ? 'CreateNew' : 'UpdateExisting');

        let roles = '';
        user.Claims.forEach((claim: Claim) => {
            roles = roles + '<string>' + claim.Value + '</string>';
        });
        body = body.replace('{claims}', roles);

        let accounts = '';
        dealerAccounts.forEach((acc: string) => {
            accounts = accounts + '<string>' + acc + '</string>';
        });
        body = body.replace('{dealeruniqueids}', accounts);

        return this.http.post(
            url,
            body,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'text/xml',
                    'SOAPAction': 'http://services.ristken.com/SignupERatingUser',
                    'impid': providerId
                }),
                responseType: 'text'
            }
        )
            .pipe(
                first(),
                map((text: string) => text.substring(text.indexOf('<ResultId>') + 10, text.indexOf('</ResultId>'))),
            );
    }

    public getUserById = (id: string): Observable<UserAccount> =>
        this.restClientSvc.get<UserAccount>(`${this.baseUrl}/useraccounts/${id}`, this.defaultHeaders())
            .pipe(
                first(),
                map((user: UserAccount) => {
                    user.Claims = user.Claims.map((claim: any) => new Claim(claim));
                    return user;
                }),
            )

    public getConvertedUsersByLegacyId = (app: string, id: number): Observable<UserAccount[]> =>
        this.restClientSvc.get<UserAccount[]>(
            `${this.baseUrl}/useraccounts/legacyapp/${app}/${id}?allaccounts=Y`,
            this.defaultHeaders()
        )

    public getLoginToken = (userAccountId: string): Observable<any> =>
        this.restClientSvc.get(
            `${this.baseUrl}/useraccounts/ssotoken/${userAccountId}`,
            this.defaultHeaders()
        )

    public deleteUserById = (id: string, permanent: boolean): Observable<boolean> =>
        this.restClientSvc.del(`${this.baseUrl}/useraccounts/${id}` + (permanent ? '?act=permanent' : ''), this.defaultHeaders())
            .pipe(
                first(),
                map((response: string) => response === 'Success'),
                catchError(() => of(false)),
            )

    public searchUser = (userName: string, firstName: string, lastName: string): Observable<UserAccount[]> => {
        const url = `${this.baseUrl}/useraccounts/filters?loginid=${userName}&lastname=${lastName}&firstname=${firstName}`;
        return this.restClientSvc.get<UserAccount[]>(url, this.defaultHeaders());
    }

    private sortSearchResult = (userAccounts: UserAccount[]): UserAccount[] =>
        userAccounts.sort((itm1: UserAccount, itm2: UserAccount) => {
            if (itm1.FirstName.toUpperCase() !== itm2.FirstName.toUpperCase()) {
                return itm1.FirstName.toUpperCase().localeCompare(itm2.FirstName.toUpperCase());
            } else if (itm1.LastName.toUpperCase() !== itm2.LastName.toUpperCase()) {
                return itm1.LastName.toUpperCase().localeCompare(itm2.LastName.toUpperCase());
            } else {
                return itm1.Email.toUpperCase().localeCompare(itm2.Email.toUpperCase());
            }
        })

    public search = (searchText: string): Observable<UserAccount[]> => {
        const baseUrl = `${this.baseUrl}/useraccounts`;
        const headers = this.defaultHeaders();

        const calls = _.cond([
            [
                _.negate(_.identity),
                () => [this.restClientSvc.get<UserAccount[]>(baseUrl, headers)]
            ],
            [
                _.identity,
                _.cond([
                    [
                        // @ts-ignore
                        _.flow(
                            // @ts-ignore
                            _.partial(_.split, _, ' '),
                            _.size,
                            // @ts-ignore
                            _.partial(_.gt, _, 1),
                        ),
                        () => {
                            const names = searchText.split(' ');
                            return [this.restClientSvc.get<UserAccount[]>(
                                `${baseUrl}/filters?&firstname=${names[0]}&lastname=${names[1]}`,
                                headers
                            )];
                        }
                    ],
                    [
                        (text: string) => emailRegExp.test(text),
                        () => [this.restClientSvc.get<UserAccount[]>(`${baseUrl}/filters?loginid=${searchText}`, headers)]
                    ],
                    [
                        _.stubTrue,
                        () => [
                            this.restClientSvc.get<UserAccount[]>(`${baseUrl}/filters?loginid=${searchText}`, headers),
                            this.restClientSvc.get<UserAccount[]>(`${baseUrl}/filters?firstname=${searchText}`, headers),
                            this.restClientSvc.get<UserAccount[]>(`${baseUrl}/filters?lastname=${searchText}`, headers),
                        ]
                    ]
                ])
            ],
        ])(searchText);

        return forkJoin(calls)
            .pipe(
                first(),
                map((searchResults: [UserAccount[]]) =>
                    _.flow([
                        _.flatten,
                        _.partial(_.uniqBy, _, (user: UserAccount) => user.Id),
                        this.sortSearchResult,
                    ])(searchResults)
                )
            );
    }

    public getByUserName = (userName: string): Observable<UserAccount[]> =>
        this.restClientSvc.get<UserAccount[]>(`${this.baseUrl}/useraccounts/emailfnd/${userName}`, this.defaultHeaders())

    public saveUser = (userToSave: UserAccount, dealerAccounts: string[], providerId: string): Observable<UserAccount> =>
        (userToSave.Id ?
            this.restClientSvc.put<UserAccount>(`${this.baseUrl}/useraccounts/${userToSave.Id}`, userToSave, this.defaultHeaders()) :
            this.restClientSvc.post<UserAccount>(`${this.baseUrl}/useraccounts`, userToSave, this.defaultHeaders()))
            .pipe(
                first(),
                concatMap((user: UserAccount) =>
                    forkJoin([
                        of(user),
                        (providerId ? this.SignUpSPANReportingUser(!userToSave.Id, userToSave, providerId, dealerAccounts) : of(null))
                    ])
                ),
                concatMap(([user, id]: [UserAccount, string]) => {
                    if (userToSave.Id || !id) {
                        return of(user);
                    } else {
                        const leg = {
                            'LegacyApplication': 'Ristken.Core',
                            'LegacyTableId': id
                        };
                        user.LegacyLinks.push(leg);

                        return this.restClientSvc.put<UserAccount>(
                            `${this.baseUrl}/useraccounts/${user.Id}`,
                            user,
                            this.defaultHeaders()
                        );
                    }
                }),
            )

    public changePassword = (userAccountId: string, newPassword: string, iamToken: string = ''): Observable<IResult> => {
        const url = `${this.baseUrl}/useraccounts/${userAccountId}/passwordchange`;
        return this.restClientSvc.post<UserAccount>(
            url,
            { 'NewPassword': newPassword }
            , this.defaultHeaders(iamToken)
        )
                .pipe(
                    first(),
                    map((user: UserAccount) => ({
                        success: true,
                        payload: user,
                        error: null,
                    })),
                    catchError((error) => of({
                        success: false,
                        payload: null,
                        error,
                    })),
                );
    }

    public newPasswordOK = (userAccountId: string, newPassword: string, iamToken: string): Observable<IResult> =>
        this.restClientSvc.get<string>(
            `${this.baseUrl}/useraccounts/${userAccountId}/passwordok/${newPassword}`,
            this.defaultHeaders(iamToken)
        )
            .pipe(
                map(() => ({
                    success: true,
                    payload: null,
                    error: null,
                })),
                catchError((error) => of({
                    success: false,
                    payload: null,
                    error,
                }))
            )

    public initialPasswordOK = (providerId: string, email: string, newPassword: string): Observable<boolean> =>
        this.restClientSvc.get<string>(
            `${this.baseUrl}/useraccounts/provider/${providerId}/passwordok/${newPassword}/username/${email}`,
            this.defaultHeaders()
        )
            .pipe(
                map(() => true),
                catchError(() => of(false))
            )

    public userNameAvailable = (email: string): Observable<boolean> =>
        this.restClientSvc.get<boolean>(`${this.baseUrl}/useraccounts/usernameok/${email}`, this.defaultHeaders())
            .pipe(catchError(() => of(false)))

    private getRandomInt = (max: number): number => Math.floor(Math.random() * Math.floor(max));

    private buildTemporaryPassword = (): string => {
        let newPassword = '';
        for (let i = 0; i < 10; i++) {
            newPassword += passwordSeed[this.getRandomInt(passwordSeed.length - 1)];
        }

        return newPassword;
    }

    public buildNewUser = (firstName: string,
        lastName: string,
        email: string,
        roleNames: string[],
        initialPassword: string,
        providerName: string
    ): UserAccount => {
        const user = new UserAccount();
        user.LastName = lastName;
        user.FirstName = firstName;
        user.Email = email;
        user.IsLockedOut = false;
        user.PrimaryProviderId = this.sessionSvc.getCurrentUserProviderId();
        user.ForcePasswordChange = true;
        user.Password = initialPassword != null && initialPassword.length > 0 ? initialPassword : this.buildTemporaryPassword();
        user.UserName = email;
        user.FailedLoginCount = 0;

        user.LegacyLinks = [];
        const lnk = new LegacyLink();
        lnk.LegacyApplication = 'UserMapId';
        lnk.LegacyTableId = providerName + '_' + email;

        user.LegacyLinks.push(lnk);

        user.Claims = roleNames.map((rl: string) =>
        new Claim({
            Type: CLAIM_TYPE_ROLE,
            Value: rl
        }));

        return user;
    }

    public authenticate = (userName: string, password: string): Observable<IResult> => {
        return this.restClientSvc.post<AuthenticationModel>(
            `${this.baseUrl}/userauthentications`,
            {
                'UserName': userName,
                'Password': password,
                'TimeoutLengthMinutes' : LOGIN_TIMEOUT_MINUTES
            },
            this.defaultHeaders()
        )
            .pipe(
                first(),
                concatMap((auth: AuthenticationModel) => {
                    this.sessionSvc.setCurrentAuthInfo(auth);
                    this.sessionSvc.setCurrentUserToken(auth.TokenValue);
                    return this.getUserById(auth.Id);
                }),
                map((user: UserAccount) => {
                    this.sessionSvc.setLoggedInUser(user);
                    this.sessionSvc.setUserIsPortalAdmin(
                        Boolean(user.Claims.find((claim: Claim) => claim.Value.toUpperCase() === 'PORTAL ADMIN'))
                    );

                    return { success: true, payload: null, error: null };
                }),
                catchError((error) => of({
                    success: false,
                    payload: null,
                    error,
                })),
            );
    }

    public requestPasswordReset = (email: string, type: string): Observable<void> =>
        this.restClientSvc.post<void>(`${this.baseUrl}/useraccountsreset/password-reset`,
            {
                Email: email,
                ResetType: type,
            })
            .pipe(
                first(),
                catchError(() => of(null))
            )

    public getUserClaimsByIamToken = (iamToken: string): Observable<IResult> =>
        this.restClientSvc.get<any>(`${this.baseUrl}/tokens/claims`, this.defaultHeaders(iamToken))
            .pipe(
                first(),
                map((userInfo: any) => ({
                    success: true,
                    payload: userInfo,
                    error: null,
                })),
                catchError((error) => of({
                    success: false,
                    payload: null,
                    error,
                }))
            )
}
