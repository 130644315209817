<div class="header-wrapper">
    <header class="header">

        <div class="header-section">
            <div class="logo"></div>
        </div>

        <div class="header-section">
            <div class="dropdown-container dealership">
                <div class="ripple absolute" *ngIf="showCoachRipple">
                </div>
                <div class="dropdown-link nav-icon dealer-icon tooltip" aria-label="Let's select a dealership to begin" (click)="onExpandDropdown($event); collapseAccountManagement(); collapseAppChoices();">
                </div>
                <div class="dropdown-content-wrapper">
                    <div #dlrChoicesPnl class="dropdown-content">
                        <div class="dropdown-content-arrow"></div>
                        <div class="dropdown-content-body">
                            <div class="dropdown-content-section">
                                <div class="dropdown-content-subsection padding-top padding-bottom padding-right padding-left non-closing">
                                    <div class="p-100 non-closing input-with-submit">
                                        <input type="text" class="non-closing p-100 animating-border" ngControl="dealerSearchName" [(ngModel)]="dealerSearchName" name="dealerSearchName" id="dealerSearchName" placeholder="Dealership Name or Account Number"
                                        (focus)="handleSearchFocus()" (focusout)="handleSearchBlur()">
                                        <button class="non-closing submit" (click)="onDealerSearchChange()"></button>
                                        <span class="focus-border">
                                          <i></i>
                                        </span>
                                        <div class="lds-dual-ring non-closing" *ngIf="isSearching">
                                            <div></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="dropdown-content-subsection non-closing padding-top padding-bottom padding-right padding-left" [hidden]="dealerSearchName == null">
                                    <div class="input-label" *ngIf="userDealerships">Search Results ({{ userDealerships.length === 100 ? 'limited to ' + userDealerships.length.toString() : userDealerships.length.toString() }})</div>
                                    <select name="dealerships" class="pick-dealer-list non-closing" [(ngModel)]="selectedDealership" (change)="onDealerChange()">
                                        <option class="non-closing" *ngFor="let dlr of userDealerships" value="{{dlr.Id}}">
                                            <span *ngIf="(dlr.ProviderLinkIds||[]).length>0">{{dlr.LegalName}} - {{dlr.ProviderLinkIds[0].ProviderAccountCode}}</span>
                                            <span *ngIf="(dlr.ProviderLinkIds||[]).length==0">{{dlr.LegalName}}</span>
                                        </option>
                                    </select>
                                </div>
                                <div class="dropdown-content-subsection">
                                    <p class="padding-top padding-bottom padding-right padding-left non-closing">Recent Dealers</p>
                                </div>
                                <div class="non-closing flex flex-align-center" *ngIf="isInitialSearch">
                                    <div class="lds-dual-ring">
                                        <div></div>
                                    </div>
                                </div>
                                <div class="dropdown-content-subsection">
                                    <ul class="flex-1 pl3">
                                        <li (click)="onRecentDealerChange(dlr.Id)" *ngFor="let dlr of recentDealerships"
                                            class="x-axis-center-content align-items-flex-start pb2 underline pointer"><span class="margin-left">{{ dlr.LegalName }} - {{dlr.ProviderLinkIds[0].ProviderAccountCode}}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="full-width padding-bottom non-closing"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dropdown-container product">
                <div class="dropdown-link nav-icon product-icon tooltip" (click)="onExpandDropdown($event); collapseDealerChoices(); collapseAccountManagement();">
                </div>
                <div #appChoicesPnl class="dropdown-content">
                    <div class="dropdown-content-arrow"></div>
                    <div class="dropdown-content-body">
                        <div class="dropdown-content-section padding" *ngIf="!dealerApplications.length">
                            Oops, there are no applications available for this login.
                        </div>
                        <div
                            class="dropdown-content-section padding cursor-pointer"
                            [ngClass]="{'': selectedDealership==null&&recentDealership==null}"
                            *ngFor="let tlapp of dealerApplications"
                            (click)="onAppChange($event, tlapp.ApplicationName.toUpperCase())">
                            <div class="p-100 x-axis-center-content justify-content-space-between" *ngIf="(tlapp.ApplicationDescription == 'Menu' || tlapp.ApplicationDescription == 'Commerce' || tlapp.ApplicationDescription == 'Showcase') && (expDate != ' Subscription Required')">
                                <div class="margin-left">
                                    <div *ngIf="tlapp.ApplicationDescription === 'Showcase'">
                                        <img src="/assets/images/showcase-logo-white.svg" width="150" />
                                        <div class="text-small-x date">{{expDate}}</div>
                                    </div>
                                    <div *ngIf="tlapp.ApplicationDescription !== 'Showcase'">
                                        <div class="solution">{{tlapp.ApplicationDescription}}</div>
                                        <div class="text-small-x date">{{expDate != 'ACTIVE SUBSCRIPTION' ? expDate : ''}}</div>
                                    </div>
                                </div>
                                <div>
                                    <img src="/assets/images/icon-new-win-white.svg" width="20" height="20"/>
                                </div>
                            </div>
                            <div class="p-100 x-axis-center-content justify-content-space-between" *ngIf="(tlapp.ApplicationDescription == 'Menu' || tlapp.ApplicationDescription == 'Commerce') && (expDate == ' Subscription Required')">
                                <div class="margin-left">
                                    <span>{{tlapp.ApplicationDescription}}</span>
                                    <div class="text-small-x pt1">Account Removal Pending</div>
                                </div>
                                <img src="/assets/images/icon-new-win-white.svg" width="20" height="20"/>
                            </div>
                            <div class="p-100 x-axis-center-content justify-content-space-between" *ngIf="(tlapp.ApplicationDescription == 'Showcase') && (expDate == ' Subscription Required') ">
                                <div class="margin-left">
                                    <img src="/assets/images/showcase-logo-white.svg" width="150" />
                                    <div class="text-small-x">{{expDate}}</div>
                                </div>
                                <img src="/assets/images/icon-new-win-white.svg" width="20" height="20"/>
                            </div>
                            <div class="p-100 x-axis-center-content justify-content-space-between" *ngIf="!(tlapp.ApplicationDescription == 'Menu' || tlapp.ApplicationDescription == 'Commerce' || tlapp.ApplicationDescription == 'Showcase')">
                                <div class="margin-left">
                                    <div class="solution">{{tlapp.ApplicationDescription}}</div>
                                </div>
                                <div>
                                    <img src="/assets/images/icon-new-win-white.svg" width="20" height="20"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dropdown-container user-name text-right">
                <div class="pb1">
                    {{userFirstName}} {{userLastName}}
                </div>
                <div class="pt1 dropdown-container border-top" *ngIf="selectedDealershipName">
                    {{ selectedDealershipName.length > 20 ? selectedDealershipName.substr(0, 20) + '...' : selectedDealershipName }}
                </div>
            </div>

            <div class="dropdown-container user">
                <div class="dropdown-link nav-icon user-icon tooltip" (click)="onExpandDropdown($event); collapseDealerChoices(); collapseAppChoices();">
                </div>
                <div #userAccountPnl class="dropdown-content">
                    <div class="dropdown-content-arrow"></div>
                    <div class="dropdown-content-body">
                        <div class="dropdown-content-section padding">
                            <div class="dropdown-content-section padding-top padding-bottom align-items-flex-start">
                                <div class="small-text">Welcome back, {{userFirstName}}</div>
                                <div>{{userEmail}}</div>
                            </div>
                            <div class="dropdown-content-section padding-top padding-bottom align-items-flex-start" *ngIf="!showAccountManagement" [@expandableHeightAnimate]>
                                <div class="pointer" (click)="toggleShowAccountManagement()">Account Management</div>
                            </div>
                            <div class="dropdown-content-section padding-bottom padding-top align-items-flex-start">
                                <a class="pointer underline" href="/login">Log Out</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</div>

<div class="body-wrapper" [class.showcase-bg]="!showAccountManagement && !showCountDown">
    <div class="body-content">
        <section class="row column-container" *ngIf="showAccountManagement" [@expandableHeightAnimate]>
            <div class="column">
                <user-component></user-component>
            </div>

            <div class="column" *ngIf="isPortalAdmin">
                <account-management></account-management>
            </div>
        </section>
        <countdown *ngIf="showCountDown"></countdown>
        <div class="flex flex-column flex-align-center full-width full-height" *ngIf="!showAccountManagement && !showCountDown && currentProvider === 'FORDESP'">
            <div class="showcase-wrapper">
                <div class="flex">
                    <div class="showcase-column pt15 mr10">
                        <img class="pb4" src="/assets/images/showcase-logo-red.svg" alt="showcase logo">
                        <div class="flex">
                            <a href="https://www.taillight.com" target="_blank">
                                <button class="px6 py2 mr3">LEARN MORE</button>
                            </a>
                            <a href="https://www.taillight.com/learning-center" target="_blank">
                                <button class="px8 py2">TRAINING</button>
                            </a>
                        </div>
                    </div>
                    <div class="showcase-column">
                        <img class="showcase-image" src="/assets/images/showcase-computer.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- {{ zuluWebWistiaAutoPlayUrl }} -->
