import { Component, HostListener, OnInit } from '@angular/core';
import { Router, Params } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

import { RouteNames } from '../../modules/app.route.names';
import { WebServiceError } from '../../models/web.service.error';
import { SessionVariablesUtility } from '../../services/utilities/session.variables';
import { UserAccountService, PasswordResetTypes } from '../../services/identity-am/user.account';
import { CoreAppAuthenticationService } from '../../services/core-apps/core.app.auth';
import { TLAPPLICATIONS } from '../../services/identity-am/tlapplication';
import { TemporaryTokenService } from '../../services/utilities/temp.token';
import { Config } from '../../services/config';
import { IResult } from '../../services/utilities/rest.client';

const _title = 'Tail Light Login - F&I Management for Auto Dealers';

@Component({
    selector: 'login',
    templateUrl: './login.html',
    styleUrls: ['./login.scss']
})
export class LoginComponent implements OnInit {
    userName: string;
    password: string;
    loginError: string;
    userNameFP: string;
    passwordFP: string;
    loginErrorFP: string;
    modalActive = false;
    year: Number;
    passwordResetType = PasswordResetTypes.RESET;

    fordProtectLogin = false;
    fordProtectUrl: string;
    constructor(
        private routerSvc: Router,
        private sessionSvc: SessionVariablesUtility,
        private userAccountSvc: UserAccountService,
        private titleSvc: Title,
        private meta: Meta,
        private coreAppAuthSvc: CoreAppAuthenticationService,
        private tempTokenSvc: TemporaryTokenService,
        private config: Config
    ) {
        this.titleSvc.setTitle(_title);
        this.meta.addTag({
            name: 'description',
            content: 'Login to the Tail Light suite of F&I products.'
        });
        this.year = new Date().getFullYear();
    }

    ngOnInit(): any {
        this.sessionSvc.setCurrentAuthInfo(null);
        this.sessionSvc.setCurrentUserToken(null);
        this.sessionSvc.setLoggedInUser(null);
        this.sessionSvc.setProviderName(null);
        this.sessionSvc.OldAccountInfo = null;
        this.sessionSvc.CurrentConvertUserMode = null;

        const url = this.routerSvc.url;
        this.fordProtectUrl = this.config.get('fordProtectLoginHelp');
        this.sessionSvc.CoreAppFrom = null;
        if (url.toLowerCase().endsWith(`/${RouteNames.Login_Menu}`)) { this.sessionSvc.CoreAppFrom = TLAPPLICATIONS.MENUPRO; }
        if (url.toLowerCase().endsWith(`/${RouteNames.Login_Reports}`)) { this.sessionSvc.CoreAppFrom = TLAPPLICATIONS.TRACKING; }
        if (url.toLowerCase().endsWith(`/${RouteNames.Login_Span}`)) { this.sessionSvc.CoreAppFrom = TLAPPLICATIONS.SPAN; }
        if (url.toLowerCase().endsWith(`/${RouteNames.Login_Showcase}`)) { this.sessionSvc.CoreAppFrom = TLAPPLICATIONS.TLSHOWCASE; }
        if (url.toLowerCase().endsWith(`/${RouteNames.Ford_Protect}`)) { this.fordProtectLogin = true; }

        const params: Params = this.routerSvc.routerState.snapshot.root.queryParams;
        if (params && params.encuid){
            const encuid = params.encuid;
            const app = params.rcapp.toUpperCase();

            this.sessionSvc.CoreAppFrom = app;
            this.tempTokenSvc.getTemporaryToken()
            .subscribe(
                (token) => {
                    this.coreAppAuthSvc.getCoreAppUserInfo(encuid, (app !== 'MENUPRO' ? 'RISTKEN.CORE' : app), token)
                    .subscribe(
                        (userInfo) => {
                            this.sessionSvc.OldAccountInfo = userInfo;
                            this.routerSvc.navigate([RouteNames.ConvertAccount]);
                        }
                    );
                }
            );
        }
        if (params && params.uname) { this.userName = params.uname; }
        if (params && params['password-reset']) {
            this.passwordResetType = params['type'];
            this.modalActive = true;
        }
    }

    @HostListener('document:keyup.escape')
    onEscapeKeydown() {
        this.closeModal();
    }

    public onSubmit = (): void => {
        this.loginError = this.loginErrorFP = null;

        if (this.fordProtectLogin) {
            this.userName = this.userNameFP.trim();
            this.password = this.passwordFP.trim();
            this.coreAppAuthSvc.checkCoreAppsAuthentication(this.userName, this.password)
            .subscribe((coreAppResp: any) => {
                if (coreAppResp && (coreAppResp.ristkenCoreAuthSuccess || coreAppResp.menuProAuthSuccess)) {
                    this.sessionSvc.OldAccountInfo = _.assign(coreAppResp, { username: this.userName});
                    this.routerSvc.navigate([RouteNames.ConvertAccount]);
                } else {
                    this.loginErrorFP = 'Invalid User ID or Password';
                }
            }, () => { this.loginErrorFP = 'Invalid User ID or Password'; });
        } else {
            this.userName = this.userName.trim();
            this.password = this.password.trim();
            this.userAccountSvc.authenticate(this.userName, this.password)
                .subscribe((result: IResult) => {
                    if (result.success) {
                        this.routerSvc.navigate([RouteNames.Home]);
                    } else if (result.error.response._body && result.error.response._body.toUpperCase().indexOf('LOCKED') >= 0) {
                        this.loginError = this.loginErrorFP = 'The user account is locked. Please contact your administrator.';
                    } else {
                        this.loginError = 'Invalid User ID or Password';
                    }
                });
        }
    }

    onEditChange() { this.loginError = null; }

    onEditChangeFP() { this.loginErrorFP = null; }

    onForgotPassword() { this.modalActive = true; }

    closeModal() { this.modalActive = false; }

    openModal() { this.modalActive = true; }

    toggleModal() { this.modalActive ? this.closeModal() : this.openModal(); }

    getCurrentDate(): number { return new Date().getFullYear(); }
}
