import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApplicationSetting } from '../../models/app.setting';
import { Config } from '../config';
import { BaseIamService } from './base.iam';
import { SessionVariablesUtility } from '../utilities/session.variables';
import { RESTServiceClient } from '../utilities/rest.client';
import { map } from 'rxjs/operators';
import { WebServiceError } from '../../models/web.service.error';

@Injectable()
export class AppSettingService extends BaseIamService {

    _providerShowcaseApplicationSettings: any = {};
    _providerSpanApplicationSettings: any = {};

    constructor(
        private restClientSvc: RESTServiceClient,
        sessionSvc: SessionVariablesUtility,
        config: Config) {
        super(sessionSvc, config);
    }

    public clearAllFeatureSettings() {
        this._providerShowcaseApplicationSettings = {};
        this._providerSpanApplicationSettings = {};
    }

    public getShowCaseFeatureProviderSetting(settingKey: string, defaultValue: string = null): Observable<string> {
        return this.getProviderSettingFromStore(this._providerShowcaseApplicationSettings, 'TAILLIGHTSHOWCASE', settingKey, defaultValue);
    }

    public getSPANFeatureProviderSetting(settingKey: string, defaultValue: string = null): Observable<string> {
        return this.getProviderSettingFromStore(this._providerSpanApplicationSettings, 'SPAN', settingKey, defaultValue);
    }

    // retrieve useraccount by identifier
    private getProviderSettingFromStore(settingStore: any, appName: string,
        settingKey: string, defaultValue: string = null): Observable<string> {
        const cacheKey = this.getCacheKey('*all*', appName);
        if (!(settingStore && settingStore[cacheKey])) {
            return this.restClientSvc.get<Array<ApplicationSetting>>(
                `${this.baseUrl}/applicationsettings/application/${appName}/provider/${this.sessionSvc.getCurrentUserProviderId()}`,
                this.defaultHeaders())
                .pipe(
                    map(
                        (settings: Array<ApplicationSetting>) => {
                            settingStore = {};
                            settingStore[cacheKey] = this.onlyProviderLevelSettings(settings);
                            return this.findRetrievedSettingValue(settings, settingKey, defaultValue);
                        }
                    )
                );
        } else {
            return of(this.findRetrievedSettingValue(settingStore, settingKey, defaultValue));
        }
    }

    // retrieve useraccount by identifier
    public getTLCommerceFeatureProviderSetting(settingKey: string, defaultValue: string = null): Observable<string> {
        const COMMERCE = 'TailLightCommerce';
        const cacheKey = this.getCacheKey('*all*', COMMERCE)
        const providerAppSettings = this._providerSpanApplicationSettings ? this._providerSpanApplicationSettings[cacheKey] : null;
        const provId = this.sessionSvc.getCurrentUserProviderId();

        const u = `${this.baseUrl}/applicationsettings/application/${COMMERCE}/provider/${provId}`;
        if (!providerAppSettings) {
            return (new Observable<string>(observer => {
                this.restClientSvc.get<Array<ApplicationSetting>>(u,
                    this.defaultHeaders())
                    .subscribe(
                        (settings: Array<ApplicationSetting>) => {
                            this._providerSpanApplicationSettings[cacheKey] = this.onlyProviderLevelSettings(settings);
                            const val = this.findRetrievedSettingValue(settings, settingKey, defaultValue);
                            observer.next(val);
                            observer.complete();
                        },
                        (err: WebServiceError) => {
                            observer.error(err);
                        }
                    );
            })
            );
        } else {
            return of(this.findRetrievedSettingValue(providerAppSettings, settingKey, defaultValue));
        }
    }

    private onlyProviderLevelSettings = (settings: Array<ApplicationSetting>): Array<ApplicationSetting> => {
        const providerSettings = _.filter(settings, (sett) => sett.SettingType.toUpperCase() === 'PROVIDERBASED');
        return providerSettings;
    }

    private findRetrievedSettingValue = (settings: Array<ApplicationSetting>, key: string, defaultValue: string = null): string => {
        const found = _.find(settings, (sett) => sett.SettingKey.toUpperCase() === key.toUpperCase());
        return (found && found.SettingValue) ? found.SettingValue : defaultValue;
    }

    private getCacheKey(providerCode: string, appName: string): string {
        return `${providerCode}.${appName}`;
    }
}
