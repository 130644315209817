import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, throwError as _throw } from 'rxjs';
import { Injectable } from '@angular/core';
import { WebServiceError } from '../../models/web.service.error';
import { catchError } from 'rxjs/operators';

export interface IResult {
    success: boolean;
    payload: any;
    error: any;
}

@Injectable()
export class RESTServiceClient {
    constructor(private http: HttpClient) {  }

    public post<T>(url: string, body: any, headers?: HttpHeaders): Observable<T> {
        return this.http.post<T>(url, body, { headers })
            .pipe(
                catchError((err) => _throw(new WebServiceError(err, err.status, err.statusText || err.message)))
            );
    }

    public get<T>(url: string, headers?: HttpHeaders): Observable<T> {
        return this.http.get<T>(url, { headers })
            .pipe(
                catchError((err) => _throw(new WebServiceError(err, err.status, err.statusText || err.message)))
            );
    }

    public put<T>(url: string, body: any, headers?: HttpHeaders): Observable<T> {
        return this.http.put<T>(url, body, { headers })
            .pipe(
                catchError((err) => _throw(new WebServiceError(err, err.status, err.statusText || err.message)))
            );
    }

    public del(url: string, headers?: HttpHeaders): Observable<any> {
        return this.http.delete(url, { headers })
            .pipe(
                catchError((err) => _throw(new WebServiceError(err, err.status, err.statusText || err.message)))
            );
    }
}
