export class RouteNames {
    static Login = 'login';
    static Login_Menu = 'menu';
    static Login_Reports = 'reports';
    static Login_Span = 'erate';
    static Login_Showcase = 'showcase';
    static Ford_Protect = 'fordprotect';
    static Error = 'error';
    static Home = 'home';
    static UpdatePassword = 'update-password';
    static ForgotPassword = 'forgot-password';
    static AccountManagement = 'account-management';
    static ConvertAccount = 'newaccount-creation';
}
