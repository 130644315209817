<form class="row padding-bottom">
    <div class="p-100 margin-bottom">
        <div style="position: relative;">
            <input
                placeholder="Email"
                ngControl="emailAddress"
                class="p-100 non-closing"
                [(ngModel)]="emailAddress"
                name="email"
                required
                type="email"
                #email="ngModel"
                email
            />
            <div *ngIf="complete" class="input-status-message">
                Sent!
            </div>
        </div>
    </div>
    <div class="p-100">
        <button
            class="pointer"
            [disabled]="!emailRegExp.test(emailAddress) || complete"
            type="submit"
            (click)="onSubmit()"
            [ngClass]="{'btn': true, 'non-closing': true, 'valid': !email.invalid}"
        >
            {{type === PasswordResetTypes.RESET ? 'Send Reset' : 'Resend Create'}} Email
        </button>
    </div>
</form>
