<div class="content-container">
  <div class="content-section section-header padding border-bottom">
      <div>My Information</div>
      <div class="row x-axis-center-content" (click)="toggleChangeMode(true)">
          <p class="link" name="">Edit</p>
          <img class="edit-icon" src="../../assets/images/edit.svg" />
      </div>
  </div>
  <div class="content-section">
    <div class="relative">
      <div class="padding">
          
          <div class="row">
              {{prtlUserSvMessage}}
          </div>
      
        <div class="row">
          <div class="row-horizontal padding-top padding-bottom">
            
            <div class="p-45">
              <div class="p-50 input-label">First Name:</div>
              <div>{{portalUser?.FirstName}}</div>
            </div>
            <div class="p-50">
              <div class="p-50 input-label">Last Name:</div>
              <div>{{portalUser?.LastName}}</div>
            </div>
            
          </div>
          
          <div class="row-horizontal padding-top padding-bottom">
            <div class="p-50">
              <div class="p-50 input-label">Email:</div>
              <div class="p-90 text-disabled">{{portalUser?.Email}}</div>
            </div>
            <div class="p-50">
              <div class="p-50 input-label">User {{portalUser?.Claims?.length > 1 ? 'Roles': 'Role'}}</div>
              <ul class="user-roles text-disabled">
                <li *ngFor="let role of portalUser?.Claims">{{role.Value.toLowerCase()}}</li>
              </ul>
            </div>
          </div>
        </div>
        
        <div class="row" *ngIf="inChangeMode === true" [@inputHeightAnimate]>
          
          <div class="row-horizontal padding-top">
            <div class="p-45">
              <div class="input-label">First Name</div>
              <input id="userFirstName"
                     type="text"
                     name="userFirstName"
                     class="form-control"
                     [(ngModel)]="portalUser.FirstName"
                     #fName="ngModel" >
            </div>
            
            <div class="p-50">
              <div class="input-label">Last Name</div>
              <input id="userLastName"
                     type="text"
                     name="userLastName"
                     class="form-control"
                     [(ngModel)]="portalUser.LastName"
                     #lName="ngModel" >
            </div>

          </div>
    
            <div class="row-horizontal margin-top padding-top margin-bottom">
                <div class="row p-50 x-axis-center-content" (click)="inEditPasswordMode = !inEditPasswordMode">
                    <p class="link" name="">Edit your password</p>
                    <img class="edit-icon" src="../../assets/images/edit.svg" />
                </div>
            </div>
      
          <div class="row-horizontal padding-top">
            <div class="p-40">
              <div class="input-label required" [ngClass]="{'text-disabled': !inEditPasswordMode}">Current Password</div>
              <input id="userCurrentPassword"
                     type="password"
                     name="userCurrentPassword"
                     required
                     class="form-control"
                     [(ngModel)]="userCurrentPassword"
                     [attr.disabled]="!inEditPasswordMode?'':null"
                     #cPassword="ngModel"
                     (blur)="checkPasswordCorrect()">
            </div>
      
            <div class="p-10 check-cross-parent" *ngIf="cPassword.dirty || cPassword.touched">
              <div class="check-cross" [ngClass]="{'crossed': !userCurrentPasswordCorrect }">
                <div class="check"></div>
                <div class="cross"></div>
              </div>
            </div>
            
            <div class="p-50"></div>
          </div>
      
          <div class="row-horizontal padding-top">
            <div class="p-40">
              <div class="input-label required" [ngClass]="{'text-disabled': !inEditPasswordMode}">New Password</div>
              <input id="userNewPasswordOne"
                     type="password"
                     name="userNewPasswordOne"
                     class="form-control"
                     required
                     minlength="8"
                     [(ngModel)]="userNewPasswordOne"
                     pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@#!%*?&])[A-Za-z\d$@$!%*?&]{8,32}"
                     [attr.disabled]="!inEditPasswordMode?'':null"
                     #nPasswordOne="ngModel">
                <div class="input-message user-edit-message margin-top" *ngIf="userCurrentPassword == userNewPasswordOne && (nPasswordOne.dirty || nPasswordOne.touched )">
                    New Password cannot be the same as current password
                </div>
              <div class="input-message user-edit-message margin-top" *ngIf="(nPasswordOne.invalid && (nPasswordOne.dirty || nPasswordOne.touched ))">
                <p>Password must:</p>
                <ul class="has-circle-bullets margin-left">
                    <li>Not be the same as your current password</li>
                  <li>Be more than 8 characters long</li>
                  <li>Contain at least one of each of the following: </li>
                  <li style="list-style-type: none;">
                    <ul class="has-bullets margin-left">
                      <li>Upper case characters</li>
                      <li>Lower case characters</li>
                      <li>Numbers (0 - 9)</li>
                      <li>Special characters</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
      
            <div class="p-10 check-cross-parent">
              <div class="check-cross" [ngClass]="{'crossed': nPasswordOne.invalid || userCurrentPassword == userNewPasswordOne }" *ngIf="nPasswordOne.dirty">
                <div class="check"></div>
                <div class="cross"></div>
              </div>
            </div>
      
            <div class="p-40">
              <div class="input-label required" [ngClass]="{'text-disabled': !inEditPasswordMode}">Repeat New Password</div>
              <input id="userNewPasswordTwo"
                     type="password"
                     name="userNewPasswordTwo"
                     class="form-control"
                     required
                     [(ngModel)]="userNewPasswordTwo"
                     [attr.disabled]="!inEditPasswordMode?'':null"
                     #nPasswordTwo="ngModel" >
              <div class="input-message margin-top user-edit-message" *ngIf="(nPasswordTwo.invalid || (nPasswordOne.value !== nPasswordTwo.value)) && (nPasswordTwo.dirty || nPasswordTwo.touched)">
                <p>Password must match</p>
              </div>
            </div>
      
            <div class="p-10 check-cross-parent" >
              <div class="check-cross" [ngClass]="{'crossed': (nPasswordOne.value !== nPasswordTwo.value)}" *ngIf="nPasswordTwo.dirty">
                <div class="check"></div>
                <div class="cross"></div>
              </div>
            </div>
          </div>
          
          <div class="row-horizontal padding-top">
            <div class="row p-50 padding-bottom">
              <button class="btn btn-passive p-90" name="changeInfo" (click)="toggleChangeMode(false)">Cancel</button>
            </div>
            <div class="row p-50 padding-bottom">
              <button
                class="btn p-100"
                name="updateUser"
                [disabled]="inEditPasswordMode && (cPassword.invalid || nPasswordOne.invalid || nPasswordTwo.invalid || (nPasswordOne.value !== nPasswordTwo.value) || (cPassword.value == nPasswordOne.value) || userCurrentPasswordCorrect == false)"
                (click)="updatePortalUser()">
                <span *ngIf="userUpdating === false">Update</span>
                <div class="lds-dual-ring non-closing" *ngIf="userUpdating"><div></div></div>
              </button>
            </div>
          </div>
          
        </div>

      </div>
      <div class="row">
        <small-modal [modalVisible]="modalVisible" [modalMessage]="prtlUserSvMessage" (toggleModalVisible)="hideModal($event)"></small-modal>
      </div>
    </div>
  </div>
</div>
