import { Observable, of } from 'rxjs';
import { concatMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Config } from '../config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import * as parser from 'xml2js';
import * as pathParser from 'xml2js-xpath';
import { UserAccount } from '../../models/user.account';

const ignoreAnswer = 'l4Xj+7JFEkpC7Q0rdH3mvBX/SxbChGCRohoMi38MtXowx+Njz803mMmeL59QUcxylO1yf0iSbM8ePG4YLZRpw5IN2dlsJcIM1d+1Q+28SeoNcXHhy6bGe4B73Epc5KZwXmHOzmNazn7772XVcVVTSc9OBYGSfG0UcFfZb6BfynY=';

const soapAuthRequest = (user: UserAccount, newUser: boolean, claims: string, dealerids: string) => {
    const oldUserId = (user.LegacyLinks || []).find((lnk) => lnk.LegacyApplication.toUpperCase() === 'RISTKEN.CORE') ?
        (user.LegacyLinks || []).find((lnk) => lnk.LegacyApplication.toUpperCase() === 'RISTKEN.CORE').LegacyTableId : null;

    return `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
     xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
    <soap:Header>
        <ER_SoapAuthHeader xmlns="http://services.ristken.com">
          <AuthenticationUserName></AuthenticationUserName>
          <AuthenticationPassword></AuthenticationPassword>
        </ER_SoapAuthHeader>
      </soap:Header>
      <soap:Body><SignupERatingUser xmlns="http://services.ristken.com"><_userSignup>+
        <CreationMode>${newUser ? 'CreateNew' : 'UpdateExisting'}</CreationMode>+
        <UserName>${oldUserId === null ? user.Email : oldUserId}</UserName>
        <Password>${oldUserId || !newUser ? '' : user.Password}</Password>
        <SecretQuestion>username</SecretQuestion>
        <SecretAnswer>${ignoreAnswer}</SecretAnswer>
        <FirstName>${user.FirstName}</FirstName><MiddleName></MiddleName><LastName>${user.LastName}</LastName>
        <EmailAddress>${user.Email}</EmailAddress>
        <ActiveStart>1900-01-01</ActiveStart><ActiveEnd>2999-12-31</ActiveEnd>
        <PrimaryPhone></PrimaryPhone><Fax></Fax>
        <UserRoleNames>${claims}</UserRoleNames>
        <ProviderDealerUniqueID></ProviderDealerUniqueID><ProviderMultiDealerUniqueIDs>${dealerids}</ProviderMultiDealerUniqueIDs>
        <ProviderUserName>CORE-PORTAL-WEB</ProviderUserName>
        <ProviderZones></ProviderZones><RistkenZones></RistkenZones><RistkenDealerID>0</RistkenDealerID>
        </_userSignup></SignupERatingUser></soap:Body>
    </soap:Envelope>`;
};

const buildSoapRequestHeaders = (soapAction: string, providerid: string): HttpHeaders => {
    const reqHeaders = new HttpHeaders({
        'Content-Type': 'text/xml',
        'SOAPAction': soapAction,
        'impid': providerid
    });
    return reqHeaders;
};

const soapCoreReportingSignup = (usr: UserAccount,
    newUser: boolean, claims: string, dealerids: string, providerid: string,
    http: HttpClient, config: Config): Observable<any> => {
    const req = soapAuthRequest(usr, newUser, claims, dealerids);
    return http.post(
        config.get('erateSignupUrl'),
        req,
        { headers: buildSoapRequestHeaders('http://services.ristken.com/SignupERatingUser', providerid), responseType: 'text' }
    );
};

const parseJsonWithXPath = (jsonObj: any, xpath: string): any => {
    return pathParser.find(jsonObj, xpath) ? pathParser.find(jsonObj, xpath)[0] : null;
};

const parseXmlToJson = (xml: string): Observable<any> => {
    return (new Observable<string>(observer => {
        parser.parseString(xml,
            (err: any, res: any) => {
                !!err ? observer.error(err) : observer.next(res);
                observer.complete();
            }
        );
    }));
};

const callCoreReportingSignupToJson = (usr: UserAccount,
    newUser: boolean, claims: string, dealerids: string, providerid: string,
    http: HttpClient, config: Config): Observable<any> => {
    return soapCoreReportingSignup(
        usr, newUser, claims, dealerids, providerid,
        http, config
    )
        .pipe(
            concatMap((text) => {
                return parseXmlToJson(text);
            })
        );
};

@Injectable()
export class CoreAppSignupService {
    constructor(
        private config: Config,
        private http: HttpClient) {
    }

    public signupCoreUser = (newUser: boolean,
        user: UserAccount,
        providerid: string,
        dealerAccounts: string[]): any => {

        const roles = ('<string>' + user.Claims.map(cl => cl.Value).join('</string><string>') + '</string>')
            .replace('<string></string>', '');
        const accounts = ('<string>' + dealerAccounts.join('</string><string>') + '</string>')
            .replace('<string></string>', '');

        return callCoreReportingSignupToJson(user, newUser, roles, accounts, providerid, this.http, this.config)
            .pipe(
                map(
                    (obj) => {
                        return parseJsonWithXPath(obj, '//ResultId');
                    }
                ),
                catchError((err) => of(err))// BadRequest not ok
            );
    }
}
