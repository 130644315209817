<div class="content-container margin-bottom overflow-hidden relative" [ngClass]="{'create-new-user': createNewUser }">

    <div class="slider-container">
        <!-- Edit User Container -->
        <div class="slider-column relative">

            <div class="content-section section-header manage-users-header padding border-bottom">
                <div class="">Manage User<span *ngIf="chosenUser === null">s</span> <span *ngIf="chosenUser !== null">- {{chosenUser.FirstName}} {{chosenUser.LastName}}</span></div>
                <div class="x-axis-center-content" (click)="startNewUser($event)">
                    <p class="link" name="newUser" (click)="clearUser()">Create New User</p>
                    <img class="add-user-icon" src="../../assets/images/circle-plus.svg"/>
                </div>
            </div>

            <div class="content-section padding-right padding-left relative">
                <div class="row padding-top content-section" *ngIf="{'expanded': !createNewUser}" [@expandableHeightAnimate]>
                    <div class="p-50 input-label">Find User</div>
                    <div class="p-100 non-closing input-with-submit">
                        <input type="text" ngControl="searchText" class="form-control p-100 animating-border" [(ngModel)]="searchText"
                               (focus)="handleSearchFocus()" (focusout)="handleSearchBlur()"
                               name="searchText" id="searchText">
                        <button class="submit non-closing" (click)="onUserSearchChange()"></button>
                        <span class="focus-border">
          <i></i>
        </span>
                        <div class="lds-dual-ring non-closing" *ngIf="isSearchingUser">
                            <div></div>
                        </div>
                    </div>
                </div>
                <div class="row-horizontal padding-top padding-bottom p-100 content-section expandable" [ngClass]="{'expanded': !createNewUser}">
                    <div class="p-100">
                        <div class="p-50 input-label">
                            <span>Search Results</span><span *ngIf="searchUsers"> : ({{searchUsers ? searchUsers.length : 0}})</span>
                        </div>
                        <div class="p-100">
                            <select name="users" class="detailitem-dropdown p-100" [(ngModel)]="selectedUser" (change)="onUserChange()">
                                <option *ngFor="let usr of searchUsers" [value]="usr.Id" [disabled]="usr.Email == portalUser.UserName">
                                    {{usr.FirstName}} {{usr.LastName}} ({{usr.Email}})
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div id="userInputContainer" class="edit-user content-section expandable margin-bottom" [ngClass]="{'expanded': chosenUser!=null}">
                    <div *ngIf="chosenUser!=null">

                        <div class="row padding-top padding-bottom error-message" [ngClass]="{'hidden': !prtlUserSvMessage}">
                            <span>{{prtlUserSvMessage}}</span>
                        </div>

                        <div class="row padding-top">
                            <div class="p-50 input-label">Email:</div>
                            <div class="p-50 text-disabled" [ngClass]="{'hidden': inCreateUserMode}">{{chosenUser.Email}}</div>
                        </div>
                        <div class="row-horizontal padding-top padding-bottom">
                            <div class="p-45">
                                <div class="p-50 input-label">First Name:</div>
                                <input type="text" ngControl="userFName" class="form-control p-90" [(ngModel)]="chosenUser.FirstName" name="curUserFName">
                            </div>
                            <div class="p-50">
                                <div class="p-50 input-label">Last Name:</div>
                                <input type="text" ngControl="userLName" class="form-control p-100" [(ngModel)]="chosenUser.LastName" name="curUserLName">
                            </div>
                        </div>

                        <div class="row-horizontal">
                                <div class="p-50">
                                    <div class="p-50 input-label required">Commerce Roles:</div>
                                    <div>
                                        <input type="checkbox" [checked]="isChosenUserClaim('SPAN F and I Manager')" (click)="changeClaim('SPAN F and I Manager')" value="SPAN F and I Manager" name="fimanagerrole">
                                        <label for="fimanagerrole">F&I Manager</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" [checked]="isChosenUserClaim('SPAN F and I Manager - Rating Only')" (click)="changeClaim('SPAN F and I Manager - Rating Only')" value="SPAN F and I Manager" name="fimanagerrolerateonly">
                                        <label for="fimanagerrole">F&I Manager - Rating Only</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" [checked]="isChosenUserClaim('SPAN Dealer Office Manager')" (click)="changeClaim('SPAN Dealer Office Manager')" value="SPAN Dealer Office Manager" name="dealerofficemanager">
                                        <label for="dealerofficemanager">Office Manager</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" [checked]="isChosenUserClaim('SPAN Provider Field Rep')" (click)="changeClaim('SPAN Provider Field Rep')" value="SPAN Provider Field Rep" name="fieldrep">
                                        <label for="dealerofficemanager">Field Representative</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" disabled [checked]="isChosenUserClaim('SPAN Provider Corp')" value="SPAN Provider Corp" name="provcorp">
                                        <label for="dealerofficemanager">Provider Corporate</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" disabled [checked]="isChosenUserClaim('SPAN Provider Corp Admin')" value="SPAN Provider Corp Admin" name="provcorpadmin">
                                        <label for="dealerofficemanager">Provider Corporate Administrator</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" disabled [checked]="isChosenUserClaim('SPAN Admin')" value="SPAN Admin" name="spanadmin">
                                        <label for="dealerofficemanager">Commerce Administrator</label>
                                    </div>
                                </div>
                                <div class="p-50">
                                    <div class="p-50 input-label required">Reporting Roles:</div>
                                    <div>
                                        <input type="checkbox" [checked]="isChosenUserClaim('Tracking Dealer Admin')" (click)="changeClaim('Tracking Dealer Admin')" value="Tracking Dealer Admin" name="trackingadmin">
                                        <label for="trackingadmin">Dealer Administrator</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" [checked]="isChosenUserClaim('Tracking User')" (click)="changeClaim('Tracking User')" value="Tracking User" name="trackinguser">
                                        <label for="trackinguser">Dealer User</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" [checked]="isChosenUserClaim('Tracking Report View Only')" (click)="changeClaim('Tracking Report View Only')" value="Tracking User" name="trackinguser">
                                        <label for="trackinguser">Report View Only</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" [checked]="isChosenUserClaim('Tracking Report View Only')" (click)="changeClaim('Tracking View Only')" value="Tracking User" name="trackinguser">
                                        <label for="trackinguser">View Only</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row-horizontal">
                                &nbsp;
                            </div>
                            <div class="row-horizontal">
                                <div class="p-50">
                                    <div class="p-50 input-label required">Portal Role:</div>
                                    <div>
                                        <input type="checkbox" [checked]="isChosenUserClaim('PORTAL ADMIN')" (click)="changeClaim('PORTAL ADMIN')" value="Portal Administrator" name="portaladministrator">
                                        <label for="portaladministrator">Portal Administrator</label>
                                    </div>
                                </div>
                            </div>

                        <div class="row">
                            <div class="input-message margin-top margin-bottom" *ngIf="!currentUserClaimsOK">Please check at least one role</div>
                        </div>

                        <div class="row padding-top padding-bottom">
                            <div class="row-horizontal">
                                <div class="p-55 dealership-select input-label">Dealerships Available:</div>
                                <div class="p-5"></div>
                                <div class="p-40 dealership-select input-label required">Dealerships Associated:</div>
                            </div>
                            <div class="row-horizontal">
                                <select class="dealership-select p-55" name="availDealerships" multiple [(ngModel)]="selectedAvailDealer">
                                    <option *ngFor="let dlr of availableDealerships" [value]="dlr.Id">
                                        {{dlr.LegalName}}
                                    </option>
                                </select>
                                <div class="p-5 edit-dealership-buttons">
                                    <button name="removeDealer" (click)="addDealership($event)" class="add-dealership"></button>
                                    <button name="addDealer" (click)="removeDealership($event)" class="remove-dealership"></button>
                                </div>
                                <select class="dealership-select p-40" name="curUserDealers" multiple [(ngModel)]="selectedAssocDealer">
                                    <option *ngFor="let usrDlr of chosenUserDealerships" [value]="usrDlr.Id">
                                        {{usrDlr.LegalName}}
                                    </option>
                                </select>
                            </div>

                            <div class="row">
                                <div class="input-message dealership-length-message" *ngIf="chosenUserDealerships?.length < 1">Please select at least one dealership from the left</div>
                            </div>

                            <div class="row padding-top padding-bottom">
                                <div class="p-50 input-label">User is locked out?</div>
                                <input type="checkbox" [checked]="chosenUser.IsLockedOut" [(ngModel)]="chosenUser.IsLockedOut">
                            </div>
                            <div class="row-horizontal justify-content-flex-start">
                                <button class="btn p-30" name="saveUser" (click)="saveUser($event)"
                                        [disabled]="!chosenUser.Id || chosenUserDealerships?.length < 1 || chosenUser.Claims?.length < 1 ">
                                    <span *ngIf="!currentUserUpdating">Update User</span>
                                    <div class="lds-dual-ring" *ngIf="currentUserUpdating">
                                        <div></div>
                                    </div>
                                </button>
                                <div class="p-5"></div>
                                <button class="btn p-30" [ngClass]="{'disabled': !chosenUser.Id }" name="deleteUser" [disabled]="!chosenUser.Id" (click)="toggleDeleteUserModalVisible($event)" *ngIf="!createNewUser">Remove User</button>
                                <div class="p-5"></div>
                                <button class="btn btn-passive p-30" name="cancelUser" (click)="cancelUpdateUser($event)">Cancel</button>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="custom-modal-container" [ngClass]="{'active': deleteUserModalVisible}" (click)="toggleDeleteUserModalVisible($event)">
                        <div class="modal-content non-closing p-40">
                            <div class="non-closing">{{deleteUserModalMessage}}</div>
                            <div class="row x-axis-center-content margin-top expandable-content non-closing" [ngClass]="{'expanded': showDeleteUserModalButtons}">
                                <button class="btn btn-passive p-30" (click)="toggleDeleteUserModalVisible($event)">No</button>
                                <button class="btn p-30 non-closing" (click)="deleteUser()">
                                    <span *ngIf="!isDeletingUser" class="non-closing">Yes</span>
                                    <div class="lds-dual-ring non-closing" *ngIf="isDeletingUser">
                                        <div></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <small-modal [modalVisible]="showCurrentUserModal" [modalMessage]="currentUserModalMessage" (toggleModalVisible)="hideModal($event)"></small-modal>
                </div>

            </div>

        </div>
        <!-- End Edit User Container -->
        <!-- Create New User Container -->
        <div class="slider-column relative">

            <div class="content-section section-header manage-users-header padding border-bottom">
                <div class="x-axis-center-content" (click)="cancelNewUser()">
                    <p class="link" name="newUser">Exit New User</p>
                    <img class="add-user-icon" src="../../assets/images/circle-close.svg"/>
                </div>
            </div>

            <div class="content-section padding-right padding-left relative">
                <div class="edit-user content-section margin-bottom padding-left padding-right" [ngClass]="{'in-change-mode': createNewUser}" *ngIf="chosenUser!=null || createNewUser" [@expandableHeightAnimate]>
                    <div *ngIf="chosenUser!=null">

                        <div class="row padding-top padding-bottom error-message" [ngClass]="{'hidden': !prtlUserSvMessage}">
                            <span>{{prtlUserSvMessage}}</span>
                        </div>

                        <div class="row padding-top">
                            <div class="p-50 input-label required">Email:</div>
                            <div class="p-100 relative">
                                <input type="text" ngControl="userEmail"
                                       [disabled]="!!chosenUser.Id"
                                       class="p-100"
                                       [(ngModel)]="chosenUser.Email"
                                       name="curUserEmail"
                                       (focus)="onEmailFieldFocus()"
                                       (focusout)="checkUserNameOK()">
                                <div class="input-message margin-top margin-bottom" *ngIf="emailFieldTouched && chosenUser.Email && userNameOK != true">This email is already in use</div>
                                <div class="input-message margin-top margin-bottom" *ngIf="emailFieldTouched && chosenUser.Email && !emailRegExp.test(chosenUser.Email)">Please enter a valid email</div>
                            </div>
                        </div>
                        <div class="row-horizontal padding-top padding-bottom">
                            <div class="p-45">
                                <div class="p-50 input-label">First Name:</div>
                                <input type="text" ngControl="userFName" class="form-control p-90" [(ngModel)]="chosenUser.FirstName" name="curUserFName">
                            </div>
                            <div class="p-50">
                                <div class="p-50 input-label">Last Name:</div>
                                <input type="text" ngControl="userLName" class="form-control p-100" [(ngModel)]="chosenUser.LastName" name="curUserLName">
                            </div>
                        </div>

                        <div class="row-horizontal">
                            <div class="p-50">
                                <div class="p-50 input-label required">Commerce Roles:</div>
                                <div>
                                    <input type="checkbox" [checked]="isChosenUserClaim('SPAN F and I Manager')" (click)="changeClaim('SPAN F and I Manager')" value="SPAN F and I Manager" name="fimanagerrole">
                                    <label for="fimanagerrole">F&I Manager</label>
                                </div>
                                <div>
                                    <input type="checkbox" [checked]="isChosenUserClaim('SPAN F and I Manager - Rating Only')" (click)="changeClaim('SPAN F and I Manager - Rating Only')" value="SPAN F and I Manager" name="fimanagerrolerateonly">
                                    <label for="fimanagerrole">F&I Manager - Rating Only</label>
                                </div>
                                <div>
                                    <input type="checkbox" [checked]="isChosenUserClaim('SPAN Dealer Office Manager')" (click)="changeClaim('SPAN Dealer Office Manager')" value="SPAN Dealer Office Manager" name="dealerofficemanager">
                                    <label for="dealerofficemanager">Office Manager</label>
                                </div>
                                <div>
                                    <input type="checkbox" [checked]="isChosenUserClaim('SPAN Provider Field Rep')" (click)="changeClaim('SPAN Provider Field Rep')" value="SPAN Provider Field Rep" name="fieldrep">
                                    <label for="dealerofficemanager">Field Representative</label>
                                </div>
                                <div>
                                    <input type="checkbox" disabled [checked]="isChosenUserClaim('SPAN Provider Corp')" value="SPAN Provider Corp" name="provcorp">
                                    <label for="dealerofficemanager">Provider Corporate</label>
                                </div>
                                <div>
                                    <input type="checkbox" disabled [checked]="isChosenUserClaim('SPAN Provider Corp Admin')" value="SPAN Provider Corp Admin" name="provcorpadmin">
                                    <label for="dealerofficemanager">Provider Corporate Administrator</label>
                                </div>
                                <div>
                                    <input type="checkbox" disabled [checked]="isChosenUserClaim('SPAN Admin')" value="SPAN Admin" name="spanadmin">
                                    <label for="dealerofficemanager">Commerce Administrator</label>
                                </div>
                            </div>
                            <div class="p-50">
                                <div class="p-50 input-label required">Reporting Roles:</div>
                                <div>
                                    <input type="checkbox" [checked]="isChosenUserClaim('Tracking Dealer Admin')" (click)="changeClaim('Tracking Dealer Admin')" value="Tracking Dealer Admin" name="trackingadmin">
                                    <label for="trackingadmin">Dealer Administrator</label>
                                </div>
                                <div>
                                    <input type="checkbox" [checked]="isChosenUserClaim('Tracking User')" (click)="changeClaim('Tracking User')" value="Tracking User" name="trackinguser">
                                    <label for="trackinguser">Dealer User</label>
                                </div>
                                <div>
                                    <input type="checkbox" [checked]="isChosenUserClaim('Tracking Report View Only')" (click)="changeClaim('Tracking Report View Only')" value="Tracking User" name="trackinguser">
                                    <label for="trackinguser">Report View Only</label>
                                </div>
                                <div>
                                    <input type="checkbox" [checked]="isChosenUserClaim('Tracking Report View Only')" (click)="changeClaim('Tracking View Only')" value="Tracking User" name="trackinguser">
                                    <label for="trackinguser">View Only</label>
                                </div>
                            </div>
                        </div>
                        <div class="row-horizontal">
                            &nbsp;
                        </div>
                        <div class="row-horizontal">
                            <div class="p-50">
                                <div class="p-50 input-label required">Portal Role:</div>
                                <div>
                                    <input type="checkbox" [checked]="isChosenUserClaim('PORTAL ADMIN')" (click)="changeClaim('PORTAL ADMIN')" value="Portal Administrator" name="portaladministrator">
                                    <label for="portaladministrator">Portal Administrator</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-message margin-top margin-bottom" *ngIf="!currentUserClaimsOK">Please check at least one role</div>
                        </div>

                        <div class="row padding-top">
                            <div class="row-horizontal">
                                <div class="p-55 dealership-select input-label">Dealerships Available:</div>
                                <div class="p-5"></div>
                                <div class="p-40 dealership-select input-label required">Dealerships Associated:</div>
                            </div>
                            <div class="row-horizontal">
                                <select class="dealership-select p-55" name="availDealerships" multiple [(ngModel)]="selectedAvailDealer">
                                    <option *ngFor="let dlr of availableDealerships" [value]="dlr.Id">
                                        {{dlr.LegalName}}
                                    </option>
                                </select>
                                <div class="p-5 edit-dealership-buttons">
                                    <button name="removeDealer" (click)="addDealership($event)" class="add-dealership"></button>
                                    <button name="addDealer" (click)="removeDealership($event)" class="remove-dealership"></button>
                                </div>
                                <select class="dealership-select p-40" name="curUserDealers" multiple [(ngModel)]="selectedAssocDealer">
                                    <option *ngFor="let usrDlr of chosenUserDealerships" [value]="usrDlr.Id">
                                        {{usrDlr.LegalName}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="row">
                            <div class="input-message margin-top margin-bottom" *ngIf="chosenUserDealerships?.length < 1">Please select at least one dealership from the right</div>
                        </div>

                        <div class="row-horizontal justify-content-flex-start padding-top">
                            <div class="p-30">
                                <button class="btn p-100" name="saveUser" (click)="saveUser($event)"
                                        [disabled]="userNameOK != true || chosenUserDealerships?.length < 1 || chosenUser.Claims?.length < 1 ">
                                    <div class="" *ngIf="!newUserUpdating">Save User</div>
                                    <div class="lds-dual-ring" *ngIf="newUserUpdating">
                                        <div></div>
                                    </div>
                                </button>
                            </div>
                            <div class="p-5"></div>
                            <button class="btn btn-passive p-30" name="cancelUser" (click)="cancelNewUser($event)">Cancel</button>
                        </div>

                    </div>
                </div>

                <div class="row">
                    <small-modal [modalVisible]="showNewUserModal" [modalMessage]="newUserModalMessage" (toggleModalVisible)="hideModal($event)"></small-modal>
                </div>
            </div>

        </div>
        <!-- End Create New User Container -->
    </div>
</div>
